import React from 'react';
import HsLink from '../../_common/hs-link/hs-link';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AccountPageId } from '../../../templates/page-account';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';

import * as AccountNavigationStyles from './navigation.module.scss';
import { navigate } from 'gatsby';

const AccountNavigation = ({
    headline,
    currentPage,
    storyblokCommon,
}: {
    headline: string;
    currentPage: AccountPageId;
    storyblokCommon: StoryblokCommon;
}) => {
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [shortPageId, setShortPageId] = React.useState<AccountPageId>();

    const getShortPageName = (): AccountPageId => {
        let pageId: AccountPageId;
        if (currentPage.startsWith('Profile')) {
            pageId = 'Profile';
        } else if (currentPage.startsWith('Addresses')) {
            pageId = 'Addresses';
        } else if (currentPage.startsWith('Devices')) {
            pageId = 'Devices';
        } else {
            pageId = currentPage;
        }
        return pageId;
    };

    React.useEffect(() => {
        setShortPageId(getShortPageName());
    }, [currentPage]);
    return (
        <>
            <div className={AccountNavigationStyles.accountNavigation}>
                <h4>{headline}</h4>

                <ul>
                    <li>
                        <HsLink
                            to={self.location.pathname}
                            active={!!currentPage.startsWith('Profile')}
                            onClick={(e: any) => {
                                e.preventDefault();
                                navigate(self.location.pathname, { state: { pageId: 'Profile' } });
                            }}
                            storyblokCommon={storyblokCommon}
                        >
                            {storyblokCommon.configuration.content.account_navigation_profile}
                        </HsLink>
                    </li>

                    <li>
                        <HsLink
                            to={self.location.pathname}
                            active={!!currentPage.startsWith('Addresses')}
                            onClick={(e: any) => {
                                e.preventDefault();
                                navigate(self.location.pathname, { state: { pageId: 'Addresses' } });
                            }}
                            storyblokCommon={storyblokCommon}
                        >
                            {storyblokCommon.configuration.content.account_navigation_addresses}
                        </HsLink>
                    </li>

                    <li>
                        <HsLink
                            to={self.location.pathname}
                            active={!!currentPage.startsWith('Devices')}
                            onClick={(e: any) => {
                                e.preventDefault();
                                navigate(self.location.pathname, { state: { pageId: 'Devices' } });
                            }}
                            storyblokCommon={storyblokCommon}
                        >
                            {storyblokCommon.configuration.content.account_navigation_devices}
                        </HsLink>
                    </li>

                    {storyblokCommon.configuration.content.account_navigation_promocode && (
                        <li>
                            <HsLink
                                to={self.location.pathname}
                                active={currentPage === 'Promocode'}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(self.location.pathname, { state: { pageId: 'Promocode' } });
                                }}
                                storyblokCommon={storyblokCommon}
                            >
                                {storyblokCommon.configuration.content.account_navigation_promocode}
                            </HsLink>
                        </li>
                    )}

                    {storyblokCommon.configuration.content.account_navigation_contract_data && (
                        <li>
                            <HsLink
                                to={self.location.pathname}
                                active={!!currentPage.startsWith('ContractData')}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(self.location.pathname, { state: { pageId: 'ContractData' } });
                                }}
                                storyblokCommon={storyblokCommon}
                            >
                                {storyblokCommon.configuration.content.account_navigation_contract_data}
                            </HsLink>
                        </li>
                    )}

                    {storyblokCommon.configuration.content.account_navigation_cost_estimates && (
                        <li>
                            <HsLink
                                to={self.location.pathname}
                                active={!!currentPage.startsWith('CostEstimates')}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(self.location.pathname, { state: { pageId: 'CostEstimates' } });
                                }}
                                storyblokCommon={storyblokCommon}
                            >
                                {storyblokCommon.configuration.content.account_navigation_cost_estimates}
                            </HsLink>
                        </li>
                    )}
                </ul>

                <div className={[FormStyles.formField, AccountNavigationStyles.formField].join(' ')}>
                    <select onChange={(e) => navigate(self.location.pathname, { state: { pageId: e.target.value } })} value={shortPageId}>
                        <option value={'Profile'}>{storyblokCommon.configuration.content.account_navigation_profile}</option>
                        <option value={'Addresses'}>{storyblokCommon.configuration.content.account_navigation_addresses}</option>
                        <option value={'Devices'}>{storyblokCommon.configuration.content.account_navigation_devices}</option>
                        <option value={'Promocode'}>{storyblokCommon.configuration.content.account_navigation_promocode}</option>
                        <option value={'ContractData'}>{storyblokCommon.configuration.content.account_navigation_contract_data}</option>
                        <option value={'CostEstimates'}>{storyblokCommon.configuration.content.account_navigation_cost_estimates}</option>
                    </select>
                </div>
            </div>
        </>
    );
};

export default AccountNavigation;
