import React from 'react';
import { navigate } from 'gatsby';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';
import { AccountProfileEditNameStoryblok } from '../../../../../_shared/interfaces/storyblok';

import * as FormStylesHs from '../../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../../_scss/modules/_forms.dr.module.scss';

import * as AccountProfileEditNameStyles from './name.module.scss';

const AccountProfileEditName = ({ blok, storyblokCommon }: { blok: AccountProfileEditNameStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = React.useContext(LoadingAnimationContext);
    const [isValidationError, changeIsValidationError] = React.useState(false);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [firstName, changeFirstName] = React.useState(accountContext.accountData?.firstName ?? '');
    const [lastName, changeLastName] = React.useState(accountContext.accountData?.lastName ?? '');

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (firstName && lastName) {
            if (firstName === accountContext.accountData?.firstName && lastName === accountContext.accountData.lastName) {
                navigate(self.location.pathname, { state: { pageId: 'Profile' } });
            } else {
                showLoadingAnimation();
                accountContext
                    .updateAccountData({ firstName, lastName })
                    .then(() => {
                        hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { pageId: 'Profile' } });
                    })
                    .catch(() => {
                        hideLoadingAnimation();
                        changeIsError(true);
                    });
            }
        } else {
            changeIsValidationError(true);
        }
    };

    const getProfileData = () => {
        if (!accountContext.accountData) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .accountDataRequest()
                .catch(() => {
                    changeIsError(true);
                })
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    };

    React.useEffect(() => {
        getProfileData();
    }, []);

    React.useEffect(() => {
        changeFirstName(accountContext.accountData?.firstName ?? '');
    }, [accountContext.accountData?.firstName]);

    React.useEffect(() => {
        changeLastName(accountContext.accountData?.lastName ?? '');
    }, [accountContext.accountData?.lastName]);

    return (
        <div className={AccountProfileEditNameStyles.accountProfileEditName}>
            {!isError && accountContext.accountData && (
                <>
                    <div className={AccountProfileEditNameStyles.header}>
                        <h5>{blok.headline}</h5>
                        <p>{blok.copytext}</p>
                    </div>

                    <form noValidate={true} onSubmit={onSubmit}>
                        <div className={FormStyles.formGrid}>
                            <div className={FormStyles.formField}>
                                <label htmlFor="edit-first-name">{blok.label_first_name}*</label>
                                <input
                                    id="edit-first-name"
                                    type="text"
                                    autoComplete="given-name"
                                    maxLength={35}
                                    onChange={(e) => changeFirstName(e.target.value)}
                                    onBlur={(e) => changeFirstName(e.target.value.trim())}
                                    value={firstName}
                                />
                                {isValidationError && !firstName && <p className={FormStyles.error}>{blok.error_first_name}</p>}
                            </div>

                            <div className={FormStyles.formField}>
                                <label htmlFor="edit-last-name">{blok.label_last_name}*</label>
                                <input
                                    id="edit-last-name"
                                    type="text"
                                    autoComplete="family-name"
                                    maxLength={35}
                                    onChange={(e) => changeLastName(e.target.value)}
                                    onBlur={(e) => changeLastName(e.target.value.trim())}
                                    value={lastName}
                                />
                                {isValidationError && !lastName && <p className={FormStyles.error}>{blok.error_last_name}</p>}
                            </div>
                        </div>

                        <div className={AccountProfileEditNameStyles.buttonWrapper}>
                            <HsButton
                                appearance="secondary"
                                onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                storyblokCommon={storyblokCommon}
                            >
                                {blok.button_cancel}
                            </HsButton>
                            <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                {blok.button_save}
                            </HsButton>
                        </div>

                        <p>{blok.mandatory_legend}</p>
                    </form>
                </>
            )}
            {isError && <Error storyblokCommon={storyblokCommon} />}
        </div>
    );
};

export default AccountProfileEditName;
