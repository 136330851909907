import React from 'react';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';

const Error = ({ storyblokCommon }: { storyblokCommon: StoryblokCommon }) => {
    return (
        <div>
            <h4>{storyblokCommon.configuration.content.error_headline}</h4>

            <p>{storyblokCommon.configuration.content.error_copytext}</p>
        </div>
    );
};

export default Error;
