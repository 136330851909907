import React from 'react';
import { navigate } from 'gatsby';
import { IAddress } from '../../../../_shared/interfaces/address';
import { AccountContext } from '../../../../context/account/account.context';
import HsButtonSimple from '../../../_common/hs-button-simple/hs-button-simple';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { AccountAddressesAddressStoryblok } from '../../../../_shared/interfaces/storyblok';

import * as AccountAddressesAddressStyles from './address.module.scss';

const AccountAddressesAddress = ({
    address,
    blok,
    storyblokCommon,
}: {
    address: IAddress;
    blok: AccountAddressesAddressStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const accountContext = React.useContext(AccountContext);
    const [isMarked, setMarked] = React.useState(false);

    const deleteAddress = (e: any) => {
        e.preventDefault();
        setMarked(true);
        setTimeout(() => {
            if (window.confirm(blok.delete_confirmation)) {
                accountContext.deleteAddress(address.id ? address.id : '');
            } else {
                setMarked(false);
            }
        });
    };

    const country = storyblokCommon.configuration.content.countries.find((countryStoryblok) => address.country === countryStoryblok.id);

    return (
        <div
            className={[AccountAddressesAddressStyles.accountAddress, isMarked ? AccountAddressesAddressStyles.marked : ''].join(' ')}
            id={address.id}
        >
            <div>
                <p>
                    <strong>{address.company}</strong>
                </p>
                {address.supplement && <p>{address.supplement}</p>}
                <p>{address.street}</p>
                <p>
                    {address.postalCode} {address.city}
                </p>
                <p>{country?.name ?? ''}</p>
            </div>

            <div className={AccountAddressesAddressStyles.buttons}>
                <HsButtonSimple onClick={deleteAddress} icon="delete" storyblokCommon={storyblokCommon}>
                    {blok.delete_button}
                </HsButtonSimple>
                <HsButtonSimple
                    onClick={() => navigate(self.location.pathname, { state: { pageId: 'AddressesEdit', addressId: address.id } })}
                    icon="edit"
                    storyblokCommon={storyblokCommon}
                >
                    {blok.edit_button}
                </HsButtonSimple>
            </div>
        </div>
    );
};

export default AccountAddressesAddress;
