import React, { useContext } from 'react';
import * as stylesHs from '../../_scss/modules/_forms.hs.module.scss';
import * as stylesDr from '../../_scss/modules/_forms.dr.module.scss';
import { createContext, FC, ReactElement } from 'react';
import { StoryblokCommon } from '../../lib/storyblok/storyblok';

const Context = createContext<StoryblokCommon>({} as StoryblokCommon);

type Props = {
    children: ReactElement<any> | ReactElement<any>[];
    storyblokCommon: StoryblokCommon;
};

export const StoryblokCommonProvider: FC<Props> = ({ children, storyblokCommon }) => {
    return <Context.Provider value={storyblokCommon}>{children}</Context.Provider>;
};

export const useFormStyles = () => {
    const storyblokCommon = useContext(Context);
    const styles = storyblokCommon.configuration.content.theme === 'hs' ? { ...stylesHs } : { ...stylesDr };
    return styles;
};

export const useStoryblokConfiguration = () => {
    const storyblokCommon = useContext(Context);
    const {
        configuration: { content },
    } = storyblokCommon;
    return content;
};
