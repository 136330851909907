import React from 'react';
import { navigate } from 'gatsby';
import { AccountContext } from '../../../../context/account/account.context';
import HsButton from '../../../_common/hs-button/hs-button';
import { IAddress } from '../../../../_shared/interfaces/address';
import AccountAddressesAddress from '../address/address';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import Error from '../../../_common/error/error';
import { OrderContext } from '../../../../context/order/order.context';
import { updateAdressesInOrder } from '../../../../lib/order/order';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { AccountAddressesAddressStoryblok, AccountAddressesStoryblok } from '../../../../_shared/interfaces/storyblok';
import SbEditable from 'storyblok-react';

import * as AccountAddressesOverviewStyles from './overview.module.scss';

const AccountAddressesOverview = ({
    blok,
    addressBlok,
    storyblokCommon,
}: {
    blok: AccountAddressesStoryblok;
    addressBlok: AccountAddressesAddressStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);
    const { order, updateOrder } = React.useContext(OrderContext);

    const addAddress = (): void => {
        navigate(self.location.pathname, { state: { pageId: 'AddressesAdd' } });
    };

    React.useEffect(() => {
        if (!accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .addressesRequest()
                .catch(() => {
                    changeIsError(true);
                })
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    }, []);

    React.useEffect(() => {
        updateOrder(updateAdressesInOrder(accountContext.addresses ? accountContext.addresses : [], order));
    }, [accountContext.addresses]);

    return (
        <div className={AccountAddressesOverviewStyles.accountAddressesOverview}>
            <h5 className={AccountAddressesOverviewStyles.headline}>{blok.headline}</h5>
            {!isError && accountContext.addresses && (
                <>
                    {!accountContext.addresses.length ? (
                        <p>{blok.no_addresses}</p>
                    ) : (
                        <>
                            {accountContext.addresses.length > 1 && (
                                <div style={{ textAlign: 'right' }}>
                                    <HsButton appearance="secondary" onClick={addAddress} storyblokCommon={storyblokCommon}>
                                        {blok.add}
                                    </HsButton>
                                </div>
                            )}

                            <ul className={AccountAddressesOverviewStyles.list}>
                                {accountContext.addresses.map((address: IAddress, index) => {
                                    return (
                                        <li key={index}>
                                            <SbEditable content={addressBlok}>
                                                <AccountAddressesAddress
                                                    address={address}
                                                    blok={addressBlok}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </SbEditable>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}

                    <div style={{ textAlign: 'right' }}>
                        <HsButton appearance="secondary" onClick={addAddress} storyblokCommon={storyblokCommon}>
                            {blok.add}
                        </HsButton>
                    </div>
                </>
            )}
            {isError && <Error storyblokCommon={storyblokCommon} />}
        </div>
    );
};

export default AccountAddressesOverview;
