import React from 'react';
import { navigate } from 'gatsby';
import { useIntl } from 'react-intl';
import HsButton from '../../../_common/hs-button/hs-button';
import { AccountContext } from '../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import { IDevice } from '../../../../_shared/interfaces/device';
import ManufacturerSelect from '../../../_common/manufacturer-select/manufacturer-select';
import Article from '../../../_common/article/article';
import { IProductGroup } from '../../../order/services/services';
import { getProduct, getProductOptions } from '../../../../lib/article/article';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
    AccountDevicesEditStoryblok,
    ManufacturerSelectStoryblok,
    OrderServicesArticleStoryblok,
} from '../../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { scrollToFirstError } from '../../../../lib/helpers/helpers';

import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';

import * as AccountDevicesEditStyles from './edit.module.scss';

import * as FormStylesHs from '../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../_scss/modules/_forms.dr.module.scss';

import '../../../../_scss/react-datepicker.scss';
import SbEditable from 'storyblok-react';
import { trackEvent } from '../../../../lib/track/track';

const AccountDevicesEdit = ({
    deviceId,
    blok,
    blokManufacturerSelect,
    blokArticle,
    storyblokCommon,
}: {
    deviceId?: string;
    blok: AccountDevicesEditStoryblok;
    blokManufacturerSelect: ManufacturerSelectStoryblok;
    blokArticle: OrderServicesArticleStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const intl = useIntl();
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const { devices } = React.useContext(AccountContext);
    const accountContext = React.useContext(AccountContext);
    const [isCommonError, setIsCommonError] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const [deviceExistsError, setDeviceExistsError] = React.useState(false);

    const [currentDevice, setCurrentDevice] = React.useState<IDevice>({
        manufacturer: '',
        productName: '',
        serialNumberRepresentation: '',
    });
    const [allManufacturers, setAllManufacturers] = React.useState<{ key: string; value: string }[]>([]);
    const [productOptions, setProductOptions] = React.useState<{ [manufacturer: string]: IProductGroup[] }>();
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 5 * 12);

    const deviceExists = (manufacturer: string, name: string, serialNumber: string | undefined): boolean => {
        if (!serialNumber) {
            return false;
        } else {
            return !!devices?.find(
                (d) =>
                    d.manufacturer === manufacturer &&
                    d.productName === name &&
                    d.serialNumber === serialNumber &&
                    (!currentDevice.id || currentDevice.id !== d.id),
            );
        }
    };

    const onSubmit = (): void => {
        setDeviceExistsError(false);
        setShowErrors(false);

        if (
            !currentDevice.manufacturer ||
            !currentDevice.productName ||
            (currentDevice.serialNumberRepresentation === 'mandatory' && !currentDevice.serialNumber)
        ) {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        } else if (deviceExists(currentDevice.manufacturer, currentDevice.productName, currentDevice.serialNumber)) {
            setDeviceExistsError(true);
        } else {
            loadingAnimationContext.showLoadingAnimation();

            const productDetails = getProduct(
                currentDevice.manufacturer,
                currentDevice.productName,
                storyblokCommon.workshopServices.content,
            );

            currentDevice.smallestServiceIntervalMonths = productDetails?.service_interval
                ? parseFloat(productDetails.service_interval.toString())
                : 0;

            if (currentDevice.id) {
                trackEvent('device.change', {});
                accountContext
                    .updateDevice(currentDevice)
                    .then(() => {
                        loadingAnimationContext.hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { pageId: 'Devices', deviceId } });
                    })
                    .catch(() => {
                        loadingAnimationContext.hideLoadingAnimation();
                        setIsCommonError(true);
                    });
            } else {
                trackEvent('device.add', {});
                accountContext
                    .addDevice(currentDevice)
                    .then(() => {
                        loadingAnimationContext.hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { pageId: 'Devices' } });
                    })
                    .catch(() => {
                        loadingAnimationContext.hideLoadingAnimation();
                        setIsCommonError(true);
                    });
            }
        }
    };

    React.useEffect(() => {
        if (deviceId) {
            if (devices) {
                const existingDevice = devices.find((d: IDevice) => d.id === deviceId);
                if (existingDevice) {
                    setCurrentDevice(existingDevice);
                }
            }
        } else {
            setCurrentDevice({ manufacturer: '', productName: '', serialNumberRepresentation: 'hidden' });
        }
    }, [deviceId, devices]);

    React.useEffect(() => {
        if (storyblokCommon.configuration.content.language === 'de') {
            registerLocale('de', de);
        } else if (storyblokCommon.configuration.content.language === 'fr') {
            registerLocale('fr', fr);
        } else if (storyblokCommon.configuration.content.language === 'ko') {
            registerLocale('ko', ko);
        } else if (storyblokCommon.configuration.content.language === 'nl') {
            registerLocale('nl', nl);
        }

        setProductOptions(getProductOptions(storyblokCommon.workshopServices?.content?.manufacturer, storyblokCommon, intl));

        if (!accountContext.devices) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.devicesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }

        let manufacturers: { key: string; value: string }[];

        manufacturers = storyblokCommon.workshopServices.content.manufacturer.map((m) => {
            return { key: m.name, value: m.name };
        });

        if (!deviceId && manufacturers.length === 1) {
            setCurrentDevice({ ...currentDevice, manufacturer: manufacturers[0].value });
        }
        setAllManufacturers(manufacturers);

        setIsReady(true);
    }, []);

    return (
        <>
            {isReady && (
                <div className={AccountDevicesEditStyles.accountDeviceEdit}>
                    {currentDevice && (
                        <>
                            <h5>{deviceId ? blok.headline_edit : blok.headline_add}</h5>

                            {/*MANUFACTURER START*/}
                            {allManufacturers.length > 1 && (
                                <SbEditable content={blokManufacturerSelect}>
                                    <ManufacturerSelect
                                        blok={blokManufacturerSelect}
                                        storyblokCommon={storyblokCommon}
                                        manufacturers={allManufacturers}
                                        showErrors={showErrors}
                                        onChange={(manufacturer) =>
                                            setCurrentDevice({ productName: '', serialNumberRepresentation: '', manufacturer })
                                        }
                                        headlineSmall={true}
                                        initialValue={currentDevice.manufacturer}
                                    />
                                </SbEditable>
                            )}
                            {/*MANUFACTURER END*/}

                            <h6>{blok.product_headline}</h6>

                            {/*ARTICLE START*/}
                            {productOptions && (
                                <SbEditable content={blokArticle}>
                                    <Article
                                        blok={blokArticle}
                                        storyblokCommon={storyblokCommon}
                                        manufacturer={currentDevice.manufacturer}
                                        productOptions={productOptions}
                                        articleIndex={0}
                                        showErrors={showErrors}
                                        onChange={(a) => {
                                            let isProductChange = false;
                                            if (a.product !== currentDevice.productName) {
                                                isProductChange = true;
                                            }

                                            setCurrentDevice({
                                                ...currentDevice,
                                                productName: a.product,
                                                serialNumber: a.serialNumber,
                                                serialNumberRepresentation: a.serialNumberRepresentation ?? '',
                                                lastServiceDate: isProductChange ? undefined : currentDevice.lastServiceDate,
                                                inventoryNumber: isProductChange ? '' : currentDevice.inventoryNumber,
                                            });
                                        }}
                                        deviceOnly={true}
                                        initialValues={{
                                            product: currentDevice.productName,
                                            serialNumber: currentDevice.serialNumber,
                                            service: '',
                                        }}
                                    >
                                        {currentDevice.productName && (
                                            <>
                                                {/*LAST SERVICE START*/}
                                                <div className={FormStyles.formField}>
                                                    <label htmlFor="device-last-service-date">{blok.last_service_date_label}</label>
                                                    <div>
                                                        <DatePicker
                                                            id="device-last-service-date"
                                                            locale={storyblokCommon.configuration.content.language}
                                                            maxDate={new Date()}
                                                            minDate={minDate}
                                                            dateFormat="P"
                                                            selected={
                                                                currentDevice.lastServiceDate
                                                                    ? new Date(currentDevice.lastServiceDate)
                                                                    : undefined
                                                            }
                                                            onChange={(e) => {
                                                                setCurrentDevice({
                                                                    ...currentDevice,
                                                                    lastServiceDate: e instanceof Date ? e.toJSON() : undefined,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <span className={FormStyles.information}>{blok.last_service_date_info}</span>
                                                </div>
                                                {/*LAST SERVICE END*/}

                                                {/*INVENTORY NUMBER START*/}
                                                <div className={FormStyles.formField}>
                                                    <label htmlFor="device-inventory-number">{blok.inventory_number_label}</label>
                                                    <input
                                                        id="device-inventory-number"
                                                        name="device-inventory-number"
                                                        maxLength={100}
                                                        value={currentDevice.inventoryNumber}
                                                        onChange={(e) => {
                                                            setCurrentDevice({ ...currentDevice, inventoryNumber: e.target.value });
                                                        }}
                                                    />
                                                    <span className={FormStyles.information}>{blok.inventory_number_info}</span>
                                                </div>
                                                {/*INVENTORY NUMBER END*/}
                                            </>
                                        )}
                                    </Article>
                                </SbEditable>
                            )}
                            {/*ARTICLE END*/}

                            {isCommonError && <p className={AccountDevicesEditStyles.error}>{blok.common_error}</p>}

                            {deviceExistsError && <p className={AccountDevicesEditStyles.error}>{blok.device_exists_error}</p>}

                            <div className={AccountDevicesEditStyles.buttonWrapper}>
                                <HsButton
                                    appearance="secondary"
                                    onClick={() => navigate(self.location.pathname, { state: { pageId: 'Devices' } })}
                                    storyblokCommon={storyblokCommon}
                                >
                                    {blok.back_link}
                                </HsButton>
                                <HsButton appearance={'primary'} type={'button'} onClick={onSubmit} storyblokCommon={storyblokCommon}>
                                    {deviceId ? blok.button_edit : blok.button_add}
                                </HsButton>
                            </div>
                            <p>{blok.mandatory_legend}</p>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default AccountDevicesEdit;
