import React, { FC, FormEventHandler, useContext, useState } from 'react';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { AccountProfileEditClientNumberStoryblok } from '../../../../../_shared/interfaces/storyblok';
import { navigate } from 'gatsby';
import * as accountStyles from './client-number.module.scss';
import { useFormStyles, useStoryblokConfiguration } from '../../../../../context/storyblok/storyblok.context';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';
import { scrollToFirstError } from '../../../../../lib/helpers/helpers';
import * as FormStylesHs from '../../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../../_scss/modules/_forms.dr.module.scss';

type Props = {
    blok: AccountProfileEditClientNumberStoryblok;
};

const AccountProfileEditCustomerNumber: FC<Props> = ({ blok }) => {
    const { accountData, updateAccountData, accountDataRequest, resetContractDataList } = useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);
    const [isError, setIsError] = useState(false);
    const formStyles = useFormStyles();
    const [customerNumber, setCustomerNumber] = useState(accountData?.customerNumber);
    const [showErrors, setShowErrors] = React.useState(false);
    const configuration = useStoryblokConfiguration();
    const storyblokCommon = { configuration: { content: configuration ?? {} } } as StoryblokCommon;
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const minCharacterLength: number = 5;
    const maxCharacterLength: number = 10;

    const validateCustomerNumber = (): boolean => {
        return customerNumber ? customerNumber.length >= minCharacterLength && customerNumber.length <= maxCharacterLength : true;
    };

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!validateCustomerNumber()) {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        } else {
            if (customerNumber === accountData?.customerNumber) {
                return navigate(self.location.pathname, { state: { pageId: 'Profile' } });
            }
            showLoadingAnimation();
            try {
                await updateAccountData({ customerNumber });
                await resetContractDataList();
                hideLoadingAnimation();
                navigate(self.location.pathname, { state: { pageId: 'Profile' } });
            } catch {
                hideLoadingAnimation();
                setIsError(true);
            }
        }
    };

    const getProfileData = async () => {
        showLoadingAnimation();
        try {
            await accountDataRequest();
        } catch {
            setIsError(true);
        } finally {
            hideLoadingAnimation();
        }
    };

    React.useEffect(() => {
        if (accountData) return;
        getProfileData();
    }, []);

    return (
        <>
            {formStyles && (
                <div>
                    {!isError && accountData && (
                        <>
                            <div className={accountStyles.header}>
                                <h5>{blok.headline}</h5>
                                <p>{blok.copytext}</p>
                            </div>

                            <form noValidate={true} onSubmit={onSubmit}>
                                <div className={formStyles.formField}>
                                    <label htmlFor="edit-customer-number">{blok.label}</label>
                                    <input
                                        id="edit-customer-number"
                                        type="text"
                                        minLength={minCharacterLength}
                                        maxLength={maxCharacterLength}
                                        onChange={(e) => setCustomerNumber(e.target.value === '' ? undefined : e.target.value)}
                                        value={customerNumber}
                                        className={showErrors && !validateCustomerNumber() ? formStyles.inputValidationError : ''}
                                    />
                                    {showErrors && !validateCustomerNumber() && <p className={formStyles.error}>{blok.label_error}</p>}
                                </div>

                                <div className={accountStyles.buttonWrapper}>
                                    <HsButton
                                        appearance="secondary"
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.button_cancel}
                                    </HsButton>
                                    <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                        {blok.button_save}
                                    </HsButton>
                                </div>
                            </form>
                        </>
                    )}
                    {isError && <Error storyblokCommon={storyblokCommon} />}
                </div>
            )}
        </>
    );
};

export default AccountProfileEditCustomerNumber;
