import React, { ChangeEventHandler, FC, FormEventHandler, useContext, useState } from 'react';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { AccountProfileEditRetailerStoryblok } from '../../../../../_shared/interfaces/storyblok';
import { navigate } from 'gatsby';
import * as retailerStyles from './retailer.module.scss';
import { useFormStyles, useStoryblokConfiguration } from '../../../../../context/storyblok/storyblok.context';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';

type Props = {
    blok: AccountProfileEditRetailerStoryblok;
};

const AccountProfileEditRetailer: FC<Props> = ({ blok }) => {
    const { accountData, updateAccountData, accountDataRequest } = useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);
    const [isError, setIsError] = useState(false);
    const formStyles = useFormStyles();
    const [retailer, setRetailer] = useState(accountData?.retailer ?? '');

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (retailer === accountData?.retailer) {
            return navigate(self.location.pathname, { state: { pageId: 'Profile' } });
        }
        showLoadingAnimation();
        try {
            await updateAccountData({ retailer });
            localStorage.setItem('retailer', retailer);
            navigate(self.location.pathname, { state: { pageId: 'Profile' } });
        } catch {
            setIsError(true);
        } finally {
            hideLoadingAnimation();
        }
    };

    const getProfileData = async () => {
        showLoadingAnimation();
        try {
            await accountDataRequest();
        } catch {
            setIsError(true);
        } finally {
            hideLoadingAnimation();
        }
    };

    React.useEffect(() => {
        if (!accountData) getProfileData();
    }, []);
    const handleChange: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        setRetailer(value);
    };
    const { headline = '', button_cancel = '', button_save = '', copytext = '', label = '', select_retailer_default = '' } = blok;
    const config = useStoryblokConfiguration();
    const storyblokCommon = { configuration: { content: config ?? {} } } as StoryblokCommon;
    const retailers = config.retailers ?? [];
    if (retailers.length <= 0) return <></>;
    return (
        <>
            {formStyles && (
                <div>
                    {!isError && accountData && (
                        <>
                            <div className={retailerStyles.header}>
                                <h5>{headline}</h5>
                                <p>{copytext}</p>
                            </div>

                            <form noValidate={true} onSubmit={onSubmit}>
                                <div className={formStyles.formField}>
                                    <label htmlFor="sector">{label}</label>
                                    <div className={formStyles.formField}>
                                        <select id="sector" name="sector" onChange={handleChange} value={retailer}>
                                            <option selected={!Boolean(retailer)} value="">
                                                {select_retailer_default}
                                            </option>
                                            {retailers.map(({ id, name }, index) => {
                                                return (
                                                    <option key={index} value={id}>
                                                        {name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className={retailerStyles.buttonWrapper}>
                                    <HsButton
                                        appearance="secondary"
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {button_cancel}
                                    </HsButton>
                                    <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                        {button_save}
                                    </HsButton>
                                </div>
                            </form>
                        </>
                    )}
                    {isError && <Error storyblokCommon={storyblokCommon} />}
                </div>
            )}
        </>
    );
};

export default AccountProfileEditRetailer;
