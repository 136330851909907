import React, { FC } from 'react';
import Layout from '../components/_common/_layout/layout';
import SEO from '../components/_common/seo/seo';
import { trackPage } from '../lib/track/track';
import { useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';
import {
    PageAccountStoryblok,
    AccountProfileEditProfessionStoryblok,
    AccountProfileEditProfessionSectorStoryblok,
    AccountProfileEditContractNumberStoryblok,
    AccountProfileEditRetailerStoryblok,
    AccountContractDataStoryblok,
    AccountCostEstimatesStoryblok,
} from '../_shared/interfaces/storyblok';
import { IntlProvider } from 'react-intl';
import AccountLayout from '../components/account/_layout/layout';
import AccountUnauthorized from '../components/account/unauthorized/unauthorized';
import AccountContext from '../context/account/account.context';
import AccountProfile from '../components/account/profile/profile';
import SbEditable from 'storyblok-react';
import AccountProfileEditName from '../components/account/profile/edit/name/name';
import AccountProfileEditPhoneNumber from '../components/account/profile/edit/phone-number/phone-number';
import AccountAddressesOverview from '../components/account/addresses/overview/overview';
import AccountAddressesEdit from '../components/account/addresses/edit/edit';
import AccountDevicesOverview from '../components/account/devices/overview/overview';
import AccountDevicesEdit from '../components/account/devices/edit/edit';
import AccountPromoCode from '../components/account/promo-code/promo-code';
import { StoryblokCommonProvider } from '../context/storyblok/storyblok.context';
import { PageAccountStoryblokProvider } from '../context/storyblok/page-account-storyblok.context';
import AccountProfileEditCustomerNumber from '../components/account/profile/edit/client-number/client-number';
import { AccountProfileEditClientNumberStoryblok } from '../_shared/interfaces/storyblok';
import AccountProfileEditContractNumber from '../components/account/profile/edit/contract-number/contract-number';
import AccountProfileEditProfession from '../components/account/profile/edit/profession/profession';
import AccountProfileEditProfessionSector from '../components/account/profile/edit/profession-sector/profession-sector';
import AccountProfileEditRetailer from '../components/account/profile/edit/retailer/retailer';
import AccountContractData from '../components/account/contract-datas/contract-data/contract-data';
import AccountCostEstimates from '../components/account/cost-estimates/cost-estimates';
import { ModalContextProvider } from '../context/modal/modal.context';
export type AccountPageId =
    | 'Profile'
    | 'ProfileEditName'
    | 'ProfileEditPhone'
    | 'Addresses'
    | 'AddressesEdit'
    | 'AddressesAdd'
    | 'Devices'
    | 'DevicesEdit'
    | 'DevicesAdd'
    | 'Promocode'
    | 'ProfileEditClientNumber'
    | 'ProfileEditContractNumber'
    | 'ProfileEditProfession'
    | 'ProfileEditProfessionSector'
    | 'ProfileEditRetailer'
    | 'ContractData'
    | 'CostEstimates';

export type AccountPageContext = { story: { content: PageAccountStoryblok; full_slug: string } };

type Props = {
    pageContext: AccountPageContext;
};

const AccountPage: FC<Props> = ({ pageContext }) => {
    const pageId: AccountPageId = (typeof window !== 'undefined' && history.state?.pageId) ?? 'Profile';
    const addressId: string = (typeof window !== 'undefined' && history.state?.addressId) ?? undefined;
    const deviceId: string = (typeof window !== 'undefined' && history.state?.deviceId) ?? undefined;

    let story = pageContext.story;
    story = useStoryblok(story);
    const storyblokCommon = useStoryBlockCommon(story.full_slug);
    const accountContext = React.useContext(AccountContext);

    React.useEffect(() => {
        trackPage({
            country: process.env.GATSBY_COUNTRY,
            language: storyblokCommon.configuration.content.language,
            title: story.content.metadata.title,
            template: `Account${pageId}`,
        });
    }, [pageId]);

    return (
        <IntlProvider locale={storyblokCommon.configuration.content.language}>
            <StoryblokCommonProvider storyblokCommon={storyblokCommon}>
                <PageAccountStoryblokProvider blok={story.content}>
                    <ModalContextProvider>
                        <Layout storyblokCommon={storyblokCommon}>
                            <SEO
                                metadata={story.content.metadata}
                                config={storyblokCommon.configuration.content}
                                full_slug={story.full_slug}
                            />
                            {accountContext.account ? (
                                <AccountLayout headline={story.content.headline} currentPage={pageId} storyblokCommon={storyblokCommon}>
                                    {pageId === 'Profile' && (
                                        <SbEditable content={story.content.profile[0]}>
                                            <AccountProfile blok={story.content.profile[0]} storyblokCommon={storyblokCommon} />
                                        </SbEditable>
                                    )}
                                    {pageId === 'ProfileEditName' && (
                                        <SbEditable content={story.content.profile_edit_name[0]}>
                                            <AccountProfileEditName
                                                blok={story.content.profile_edit_name[0]}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        </SbEditable>
                                    )}
                                    {pageId === 'ProfileEditPhone' && (
                                        <AccountProfileEditPhoneNumber
                                            blok={story.content.profile_edit_phone[0]}
                                            storyblokCommon={storyblokCommon}
                                        />
                                    )}
                                    {pageId === 'ProfileEditProfession' && (
                                        <AccountProfileEditProfession
                                            blok={
                                                story.content.profile_edit_profession
                                                    ? story.content.profile_edit_profession[0]
                                                    : ({} as AccountProfileEditProfessionStoryblok)
                                            }
                                        />
                                    )}
                                    {pageId === 'ProfileEditProfessionSector' && (
                                        <AccountProfileEditProfessionSector
                                            blok={
                                                story.content.profile_edit_profession_sector
                                                    ? story.content.profile_edit_profession_sector[0]
                                                    : ({} as AccountProfileEditProfessionSectorStoryblok)
                                            }
                                        />
                                    )}
                                    {pageId === 'ProfileEditClientNumber' && (
                                        <AccountProfileEditCustomerNumber
                                            blok={
                                                story.content.profile_edit_client_number
                                                    ? story.content.profile_edit_client_number[0]
                                                    : ({} as AccountProfileEditClientNumberStoryblok)
                                            }
                                        />
                                    )}
                                    {pageId === 'ProfileEditContractNumber' && (
                                        <AccountProfileEditContractNumber
                                            blok={
                                                story.content.profile_edit_contract_number
                                                    ? story.content.profile_edit_contract_number[0]
                                                    : ({} as AccountProfileEditContractNumberStoryblok)
                                            }
                                        />
                                    )}
                                    {pageId === 'ProfileEditRetailer' && (
                                        <AccountProfileEditRetailer
                                            blok={
                                                story.content.profile_edit_retailer
                                                    ? story.content.profile_edit_retailer[0]
                                                    : ({} as AccountProfileEditRetailerStoryblok)
                                            }
                                        />
                                    )}
                                    {pageId === 'Addresses' && (
                                        <SbEditable content={story.content.addresses[0]}>
                                            <AccountAddressesOverview
                                                blok={story.content.addresses[0]}
                                                addressBlok={story.content.addresses_address[0]}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        </SbEditable>
                                    )}
                                    {pageId === 'AddressesAdd' && (
                                        <SbEditable content={story.content.addresses_edit[0]}>
                                            <AccountAddressesEdit
                                                blok={story.content.addresses_edit[0]}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        </SbEditable>
                                    )}
                                    {pageId === 'AddressesEdit' && (
                                        <SbEditable content={story.content.addresses_edit[0]}>
                                            <AccountAddressesEdit
                                                addressId={addressId}
                                                blok={story.content.addresses_edit[0]}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        </SbEditable>
                                    )}

                                    {pageId === 'Devices' && (
                                        <SbEditable content={story.content.devices_overview[0]}>
                                            <AccountDevicesOverview
                                                blok={story.content.devices_overview[0]}
                                                blokDevice={story.content.devices_device[0]}
                                                storyblokCommon={storyblokCommon}
                                            />
                                        </SbEditable>
                                    )}

                                    {(pageId === 'DevicesEdit' || pageId === 'DevicesAdd') && (
                                        <SbEditable content={story.content.devices_edit[0]}>
                                            <AccountDevicesEdit
                                                blok={story.content.devices_edit[0]}
                                                blokArticle={story.content.devices_edit_article[0]}
                                                blokManufacturerSelect={story.content.devices_edit_manufacturer[0]}
                                                storyblokCommon={storyblokCommon}
                                                deviceId={deviceId}
                                            />
                                        </SbEditable>
                                    )}

                                    {storyblokCommon.configuration.content.account_navigation_promocode && pageId === 'Promocode' && (
                                        <SbEditable content={story.content.promocode[0]}>
                                            <AccountPromoCode blok={story.content.promocode[0]} storyblokCommon={storyblokCommon} />
                                        </SbEditable>
                                    )}

                                    {storyblokCommon.configuration.content.account_navigation_contract_data &&
                                        pageId === 'ContractData' && (
                                            <AccountContractData
                                                blok={
                                                    story.content.contract_data
                                                        ? story.content.contract_data[0]
                                                        : ({} as AccountContractDataStoryblok)
                                                }
                                                storyblokCommon={storyblokCommon}
                                            />
                                        )}

                                    {storyblokCommon.configuration.content.account_navigation_cost_estimates &&
                                        pageId === 'CostEstimates' && (
                                            <AccountCostEstimates
                                                blok={
                                                    story.content.cost_estimates
                                                        ? story.content.cost_estimates[0]
                                                        : ({} as AccountCostEstimatesStoryblok)
                                                }
                                                storyblokCommon={storyblokCommon}
                                            />
                                        )}
                                </AccountLayout>
                            ) : (
                                <SbEditable content={story.content.unauthorized[0]}>
                                    <AccountUnauthorized blok={story.content.unauthorized[0]} storyblokCommon={storyblokCommon} />
                                </SbEditable>
                            )}
                        </Layout>
                    </ModalContextProvider>
                </PageAccountStoryblokProvider>
            </StoryblokCommonProvider>
        </IntlProvider>
    );
};

export default AccountPage;
