import Error from '../../_common/error/error';
import React from 'react';
import { navigate } from 'gatsby';
import { AccountContext } from '../../../context/account/account.context';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import HsButtonSimple from '../../_common/hs-button-simple/hs-button-simple';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AccountProfileStoryblok } from '../../../_shared/interfaces/storyblok';

import * as AccountProfileStylesHs from './profile.hs.module.scss';
import * as AccountProfileStylesDr from './profile.dr.module.scss';

import { useFormStyles } from '../../../context/storyblok/storyblok.context';

const AccountProfile = ({ blok, storyblokCommon }: { blok: AccountProfileStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);
    const AccountProfileStyles = storyblokCommon.configuration.content.theme === 'dr' ? AccountProfileStylesHs : AccountProfileStylesDr;
    const formStyles = useFormStyles();

    const toogleNewsletter = (e: React.ChangeEvent<HTMLInputElement>): void => {
        loadingAnimationContext.showLoadingAnimation();
        accountContext
            .updateAccountData({ newsletter: e.target.checked })
            .catch(() => {
                changeIsError(true);
            })
            .finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
    };

    const requestProfileData = (): void => {
        loadingAnimationContext.showLoadingAnimation();
        accountContext
            .accountDataRequest()
            .catch(() => {
                changeIsError(true);
            })
            .finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
    };

    React.useEffect(() => {
        requestProfileData();
    }, []);
    return (
        <div className={AccountProfileStyles.accountProfile}>
            <h5 className={AccountProfileStyles.headline}>{blok.headline}</h5>
            {!isError && accountContext.accountData && (
                <>
                    <ul>
                        <li>
                            <fieldset>
                                <legend>{blok.field_name}</legend>
                                <div>
                                    <p>
                                        {accountContext.accountData.firstName} {accountContext.accountData.lastName}
                                        {!accountContext.accountData.firstName && !accountContext.accountData.lastName && (
                                            <span>{blok.no_data}</span>
                                        )}
                                    </p>

                                    <HsButtonSimple
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'ProfileEditName' } })}
                                        icon="edit"
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.edit_button}
                                    </HsButtonSimple>
                                </div>
                            </fieldset>
                        </li>

                        <li>
                            <fieldset>
                                <legend>{blok.field_email}</legend>
                                <div>
                                    <p>{accountContext.accountData.eMail}</p>
                                </div>
                            </fieldset>
                        </li>

                        <li>
                            <fieldset>
                                <legend>{blok.field_phone}</legend>
                                <div>
                                    <p>
                                        {accountContext.accountData.phoneNumber}
                                        {!accountContext.accountData.phoneNumber && <span>{blok.no_data}</span>}
                                    </p>

                                    <HsButtonSimple
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'ProfileEditPhone' } })}
                                        icon="edit"
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.edit_button}
                                    </HsButtonSimple>
                                </div>
                            </fieldset>
                        </li>

                        <li>
                            <fieldset>
                                <legend>{blok.field_password}</legend>
                                <div>
                                    <p>**********</p>
                                    <HsButtonSimple
                                        onClick={() =>
                                            accountContext.changePassword(
                                                storyblokCommon.configuration.content.language,
                                                storyblokCommon.configuration.content.theme,
                                            )
                                        }
                                        icon="edit"
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.edit_button}
                                    </HsButtonSimple>
                                </div>
                            </fieldset>
                        </li>
                        {blok.field_profession_sector && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_profession_sector}</legend>
                                    <div>
                                        <p>
                                            {accountContext.accountData.business}
                                            {!accountContext.accountData.business && <span>{blok.no_data}</span>}
                                        </p>

                                        <HsButtonSimple
                                            onClick={() =>
                                                navigate(self.location.pathname, { state: { pageId: 'ProfileEditProfessionSector' } })
                                            }
                                            icon="edit"
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.edit_button}
                                        </HsButtonSimple>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                        {blok.field_profession && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_profession}</legend>
                                    <div>
                                        <p>
                                            {accountContext.accountData.profession}
                                            {!accountContext.accountData.profession && <span>{blok.no_data}</span>}
                                        </p>

                                        <HsButtonSimple
                                            onClick={() => navigate(self.location.pathname, { state: { pageId: 'ProfileEditProfession' } })}
                                            icon="edit"
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.edit_button}
                                        </HsButtonSimple>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                        {blok.field_client_number && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_client_number}</legend>
                                    <div>
                                        <p>
                                            {accountContext.accountData.customerNumber}
                                            {!accountContext.accountData.customerNumber && <span>{blok.no_data}</span>}
                                        </p>

                                        <HsButtonSimple
                                            onClick={() =>
                                                navigate(self.location.pathname, { state: { pageId: 'ProfileEditClientNumber' } })
                                            }
                                            icon="edit"
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.edit_button}
                                        </HsButtonSimple>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                        {blok.field_contract_number && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_contract_number}</legend>
                                    <div>
                                        <p>
                                            {accountContext.accountData.contractNumber}
                                            {!accountContext.accountData.contractNumber && <span>{blok.no_data}</span>}
                                        </p>

                                        <HsButtonSimple
                                            onClick={() =>
                                                navigate(self.location.pathname, { state: { pageId: 'ProfileEditContractNumber' } })
                                            }
                                            icon="edit"
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.edit_button}
                                        </HsButtonSimple>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                        {blok.field_retailer && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_retailer}</legend>
                                    <div>
                                        <p>
                                            {
                                                (storyblokCommon.configuration.content.retailers ?? []).find(
                                                    ({ id }) => id === accountContext.accountData?.retailer,
                                                )?.name
                                            }
                                            {!accountContext.accountData.retailer && <span>{blok.no_data}</span>}
                                        </p>

                                        <HsButtonSimple
                                            onClick={() => navigate(self.location.pathname, { state: { pageId: 'ProfileEditRetailer' } })}
                                            icon="edit"
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.edit_button}
                                        </HsButtonSimple>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                        {blok.field_newsletter_legend && (
                            <li>
                                <fieldset>
                                    <legend>{blok.field_newsletter_legend}</legend>
                                    <div>
                                        <form>
                                            <div>
                                                <input
                                                    id="profile-newsletter"
                                                    type="checkbox"
                                                    onChange={toogleNewsletter}
                                                    checked={accountContext.accountData.newsletter}
                                                />
                                                <label htmlFor="profile-newsletter" className={formStyles.checkboxLabel}>
                                                    {blok.field_newsletter_label}
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                </fieldset>
                            </li>
                        )}
                    </ul>
                </>
            )}
            {isError && <Error storyblokCommon={storyblokCommon} />}
        </div>
    );
};

export default AccountProfile;
