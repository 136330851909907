import React, { FC, FormEventHandler, useContext, useState } from 'react';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { AccountProfileEditProfessionSectorStoryblok } from '../../../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';
import { navigate } from 'gatsby';

import * as professionSectorStyles from './profession-sector.module.scss';
import SectorSelector from '../../../sector-selector/sector-selector';
import { useFormStyles, useStoryblokConfiguration } from '../../../../../context/storyblok/storyblok.context';
type Props = {
    blok: AccountProfileEditProfessionSectorStoryblok;
};

const AccountProfileEditProfessionSector: FC<Props> = ({ blok }) => {
    const { accountData, updateAccountData, accountDataRequest } = useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);
    const [isError, setIsError] = useState(false);
    const formStyles = useFormStyles();
    const [business, setBusiness] = useState(accountData?.business ?? '');

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (business === accountData?.business) {
            return navigate(self.location.pathname, { state: { pageId: 'Profile' } });
        }
        showLoadingAnimation();
        try {
            await updateAccountData({ business });
            hideLoadingAnimation();
            navigate(self.location.pathname, { state: { pageId: 'Profile' } });
        } catch {
            hideLoadingAnimation();
            setIsError(true);
        }
    };

    const getProfileData = async () => {
        showLoadingAnimation();
        try {
            await accountDataRequest();
        } catch {
            setIsError(true);
        } finally {
            hideLoadingAnimation();
        }
    };

    React.useEffect(() => {
        if (!accountData) getProfileData();
    }, []);
    const configuration = useStoryblokConfiguration();
    const storyblokCommon = { configuration: { content: configuration ?? {} } } as StoryblokCommon;
    const { headline = '', button_cancel = '', button_save = '', copytext = '', label = '' } = blok;
    return (
        <>
            {formStyles && (
                <div>
                    {!isError && accountData && (
                        <>
                            <div className={professionSectorStyles.header}>
                                <h5>{headline}</h5>
                                <p>{copytext}</p>
                            </div>

                            <form noValidate={true} onSubmit={onSubmit}>
                                <div className={formStyles.formField}>
                                    <label htmlFor="sector-selector">{label}</label>
                                    <SectorSelector id="sector-selector" value={business} onChange={setBusiness} />
                                </div>

                                <div className={professionSectorStyles.buttonWrapper}>
                                    <HsButton
                                        appearance="secondary"
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {button_cancel}
                                    </HsButton>
                                    <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                        {button_save}
                                    </HsButton>
                                </div>
                            </form>
                        </>
                    )}
                    {isError && <Error storyblokCommon={storyblokCommon} />}
                </div>
            )}
        </>
    );
};

export default AccountProfileEditProfessionSector;
