import React from 'react';
import { navigate } from 'gatsby';
import HsButton from '../../../_common/hs-button/hs-button';
import AddressForm from '../../../_common/address-form/address-form';
import { IAddress } from '../../../../_shared/interfaces/address';
import { AccountContext } from '../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import { defaultAddress } from '../../../../lib/helpers/helpers';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import { AccountAddressesEditStoryblok } from '../../../../_shared/interfaces/storyblok';

import * as AccountAddressesEditStyles from './edit.module.scss';

const AccountAddressesEdit = ({
    addressId,
    blok,
    storyblokCommon,
}: {
    addressId?: string;
    blok: AccountAddressesEditStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const { addresses } = React.useContext(AccountContext);
    const accountContext = React.useContext(AccountContext);
    const [isError, setError] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [initialAddress, changeInitialAddress] = React.useState<IAddress | null>(null);

    let submitAddressForm = async (): Promise<IAddress> => {
        return defaultAddress;
    };

    const setSubmitFunction = (submitFunction: () => any): void => {
        submitAddressForm = submitFunction;
    };

    const onSubmit = (): void => {
        submitAddressForm()
            .then((updatedAddress) => {
                loadingAnimationContext.showLoadingAnimation();
                if (addressId) {
                    accountContext
                        .updateAddress(updatedAddress)
                        .then(() => {
                            loadingAnimationContext.hideLoadingAnimation();
                            navigate(self.location.pathname, { state: { pageId: 'Addresses', addressId } });
                        })
                        .catch(() => {
                            loadingAnimationContext.hideLoadingAnimation();
                            setError(true);
                        });
                } else {
                    accountContext
                        .addAddress(updatedAddress)
                        .then(() => {
                            loadingAnimationContext.hideLoadingAnimation();
                            navigate(self.location.pathname, { state: { pageId: 'Addresses' } });
                        })
                        .catch(() => {
                            loadingAnimationContext.hideLoadingAnimation();
                            setError(true);
                        });
                }
            })
            .catch(() => {
                setShowError(true);
            });
    };

    React.useEffect(() => {
        if (addressId) {
            if (addresses) {
                const existingAddress = addresses.find((a: IAddress) => a.id === addressId);
                if (existingAddress) {
                    changeInitialAddress(existingAddress);
                }
            }
        } else {
            changeInitialAddress(defaultAddress);
        }
    }, [addressId, addresses]);

    React.useEffect(() => {
        if (!accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext.addressesRequest().finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
        }
    }, []);

    return (
        <div className={AccountAddressesEditStyles.accountAddressEdit}>
            {initialAddress && (
                <>
                    <h5>{addressId ? blok.headline_edit : blok.headline_add}</h5>
                    <AddressForm
                        initialAddress={initialAddress}
                        setSubmitCall={setSubmitFunction}
                        showErrors={showError}
                        storyblokCommon={storyblokCommon}
                    />

                    {isError && <p className={AccountAddressesEditStyles.error}>{blok.common_error}</p>}

                    <div className={AccountAddressesEditStyles.buttonWrapper}>
                        <HsButton
                            appearance="secondary"
                            onClick={() => navigate(self.location.pathname, { state: { pageId: 'Addresses' } })}
                            storyblokCommon={storyblokCommon}
                        >
                            {blok.back_link_text}
                        </HsButton>
                        <HsButton appearance={'primary'} type={'button'} onClick={onSubmit} storyblokCommon={storyblokCommon}>
                            {addressId ? blok.button_edit : blok.button_add}
                        </HsButton>
                    </div>

                    <p>{blok.mandatory_legend}</p>
                </>
            )}
        </div>
    );
};

export default AccountAddressesEdit;
