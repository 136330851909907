import React, { useContext } from 'react';
import { AccountContext } from '../../../context/account/account.context';
import HsButton from '../../_common/hs-button/hs-button';
import { IAddress } from '../../../_shared/interfaces/address';
import LoadingAnimationContext from '../../../context/loading-animation/loading-animation.context';
import Error from '../../_common/error/error';
import { defaultAddress, isAddressValid, scrollToFirstError } from '../../../lib/helpers/helpers';
import axios from 'axios';
import AddressListPicker from '../../_common/address-list-picker/address-list-picker';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AccountPromocodeStoryblok } from '../../../_shared/interfaces/storyblok';
import { SectorSelector } from '../sector-selector/sector-selector';

import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';

import * as PromoCodeStyles from './promo-code.module.scss';
import { trim } from 'lodash';

export type ContactData = {
    firstName: string;
    lastName: string;
    promoCode: string;
    disclaimer: boolean;
    address: IAddress;
    profession: string | null;
    business: string | null;
};

const AccountPromoCode = ({ blok, storyblokCommon }: { blok: AccountPromocodeStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);
    const [showErrors, setShowErrors] = React.useState(false);
    const [addressesLoaded, setAddressesLoaded] = React.useState(false);
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [pageState, setPageState] = React.useState<'default' | 'success' | 'error'>('default');
    const sendMailUrl = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_CONTACT}`.replace(
        '%%COUNTRY-ID%%',
        process.env.GATSBY_COUNTRY ?? 'de',
    );
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const [contactData, setContactData] = React.useState<ContactData>({
        firstName: accountContext.accountData?.firstName ?? '',
        lastName: accountContext.accountData?.lastName ?? '',
        promoCode: '',
        disclaimer: false,
        address: defaultAddress,
        profession: accountContext.accountData?.profession ?? '',
        business: accountContext.accountData?.business ?? '',
    });

    const updateContactData = (data: ContactData): void => {
        setContactData(data);
    };

    const sendMail = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            axios
                .post<boolean>(sendMailUrl, {
                    subject: blok.email_subject,
                    body: getEmailBody(),
                    senderEMail: blok.email_sender,
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };

    const getEmailBody = (): string => {
        return (
            `${blok.email_intro}\n\n` +
            `${blok.label}: ${contactData.promoCode}\n` +
            `${storyblokCommon.configuration.content.contact_first_name_label}: ${contactData.firstName}\n` +
            `${storyblokCommon.configuration.content.contact_last_name_label}: ${contactData.lastName}\n` +
            `${storyblokCommon.configuration.content.contact_email_label}: ${accountContext.accountData?.eMail}\n` +
            `${storyblokCommon.configuration.content.address_company_label}: ${contactData.address.company}\n` +
            `${blok.profession_input_label}: ${contactData.profession}\n` +
            `${blok.profession_sector_selector_label}: ${contactData.business}\n` +
            `${storyblokCommon.configuration.content.address_supplement_label}: ${contactData.address.supplement}\n` +
            `${storyblokCommon.configuration.content.address_street_label}: ${contactData.address.street}\n` +
            `${storyblokCommon.configuration.content.address_postal_code_label}: ${contactData.address.postalCode}\n` +
            `${storyblokCommon.configuration.content.address_city_label}: ${contactData.address.city}\n` +
            `${storyblokCommon.configuration.content.address_country_label}: ${contactData.address.country}\n`
        );
    };

    const saveAccountData = async (showLoading: boolean) => {
        if (showLoading) {
            showLoadingAnimation();
        }

        const { firstName, lastName, profession, business } = contactData;

        const accountData = accountContext.accountData;
        if (
            firstName &&
            lastName &&
            (accountData?.business !== business ||
                accountData?.profession !== profession ||
                accountData?.firstName !== firstName ||
                accountData?.lastName !== lastName)
        ) {
            await accountContext.updateAccountData({
                firstName,
                lastName,
                profession: profession as string,
                business: business as string,
            });
        }

        if (showLoading) {
            hideLoadingAnimation();
        }
    };

    const trimValues = () => {
        contactData.firstName = contactData.firstName.trim();
        contactData.lastName = contactData.lastName.trim();
        contactData.promoCode = contactData.promoCode.trim();
        contactData.business = contactData.business?.trim() ?? '';
        contactData.profession = contactData.profession?.trim() ?? '';
    };

    const onSubmit = () => {
        const hasErrors = !(
            isAddressValid(contactData.address) &&
            contactData.firstName &&
            contactData.lastName &&
            contactData.disclaimer &&
            contactData.promoCode &&
            contactData.profession &&
            contactData.business
        );

        setShowErrors(hasErrors);
        trimValues();

        if (!hasErrors) {
            showLoadingAnimation();
            saveAccountData(false);
            sendMail()
                .then(() => {
                    setPageState('success');
                })
                .catch(() => {
                    setPageState('error');
                })
                .finally(() => {
                    hideLoadingAnimation();
                });
        } else {
            scrollToFirstError(formStylesError);
        }
    };

    const requestProfileDataAndAddresses = async () => {
        if (!accountContext.accountData || !accountContext.addresses) {
            showLoadingAnimation();
        }

        if (!accountContext.accountData) {
            await accountContext.accountDataRequest();
        }

        if (!accountContext.addresses) {
            await accountContext.addressesRequest();
        }

        hideLoadingAnimation();
    };

    React.useEffect(() => {
        requestProfileDataAndAddresses();
    }, []);

    React.useEffect(() => {
        updateContactData({
            firstName: accountContext.accountData?.firstName ?? '',
            lastName: accountContext.accountData?.lastName ?? '',
            promoCode: contactData.promoCode ?? '',
            disclaimer: false,
            address: contactData.address ?? undefined,
            profession: accountContext.accountData?.profession ?? '',
            business: accountContext.accountData?.business ?? '',
        });
    }, [accountContext.accountData]);

    React.useEffect(() => {
        setAddressesLoaded(!!accountContext.addresses);
    }, [accountContext.addresses]);

    return (
        <>
            {accountContext.accountData && (
                <>
                    <div className={PromoCodeStyles.promoCode}>
                        {pageState === 'default' && (
                            <>
                                <div className={PromoCodeStyles.header}>
                                    <h5 className={PromoCodeStyles.headline}>{blok.headline}</h5>
                                    <p>{blok.copytext}</p>
                                </div>

                                <form noValidate={true}>
                                    <div className={FormStyles.formGrid}>
                                        <div className={FormStyles.formField}>
                                            <label htmlFor={'promocode'}>{blok.label}*</label>
                                            <input
                                                id={'promocode'}
                                                name="promocode"
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => updateContactData({ ...contactData, promoCode: e.target.value })}
                                                value={contactData.promoCode}
                                                className={showErrors && !contactData.promoCode ? FormStyles.inputValidationError : ''}
                                            />
                                            {showErrors && !contactData.promoCode && <p className={FormStyles.error}>{blok.error}</p>}
                                        </div>
                                    </div>

                                    <div className={FormStyles.formGrid}>
                                        <div className={FormStyles.formField}>
                                            <label htmlFor="first-name">
                                                {storyblokCommon.configuration.content.contact_first_name_label}*
                                            </label>
                                            <input
                                                id="first-name"
                                                type="text"
                                                autoComplete="given-name"
                                                maxLength={35}
                                                onChange={(e) => updateContactData({ ...contactData, firstName: e.target.value })}
                                                value={contactData.firstName}
                                            />
                                            {showErrors && !contactData.firstName && (
                                                <p className={FormStyles.error}>
                                                    {storyblokCommon.configuration.content.contact_first_name_error}
                                                </p>
                                            )}
                                        </div>

                                        <div className={FormStyles.formField}>
                                            <label htmlFor="last-name">
                                                {storyblokCommon.configuration.content.contact_last_name_label}*
                                            </label>
                                            <input
                                                id="last-name"
                                                type="text"
                                                autoComplete="family-name"
                                                maxLength={35}
                                                onChange={(e) => updateContactData({ ...contactData, lastName: e.target.value })}
                                                value={contactData.lastName}
                                            />
                                            {showErrors && !contactData.lastName && (
                                                <p className={FormStyles.error}>
                                                    {storyblokCommon.configuration.content.contact_last_name_error}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {blok.profession_input_label && blok.profession_sector_selector_label && (
                                        <div className={FormStyles.formGrid}>
                                            <div className={FormStyles.formField}>
                                                <label htmlFor="job-name">{blok.profession_input_label}</label>

                                                <input
                                                    id="job-name"
                                                    type="text"
                                                    onChange={(e) => updateContactData({ ...contactData, profession: e.target.value })}
                                                    value={contactData.profession ?? ''}
                                                    className={showErrors && !contactData.profession ? FormStyles.inputValidationError : ''}
                                                />
                                                {showErrors && !contactData.profession && (
                                                    <p className={FormStyles.error}>{blok.profession_error}</p>
                                                )}
                                            </div>

                                            <div className={FormStyles.formField}>
                                                <label htmlFor="selector">{blok.profession_sector_selector_label}</label>

                                                <SectorSelector
                                                    id="selector"
                                                    onChange={(e) => updateContactData({ ...contactData, business: e })}
                                                    value={contactData.business ?? ''}
                                                    showErrors={showErrors}
                                                    errorMessage={blok.profession_sector_error}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </form>

                                <div className={PromoCodeStyles.sectionAddress}>
                                    <label>{blok.subheading}*</label>
                                    {addressesLoaded && (
                                        <AddressListPicker
                                            storyblokCommon={storyblokCommon}
                                            onAddressChange={(addressId) => {
                                                if (accountContext.addresses) {
                                                    const address = accountContext.addresses.find((a) => a.id === addressId);
                                                    if (address) {
                                                        updateContactData({ ...contactData, address });
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                </div>

                                <form>
                                    <div>
                                        <input
                                            id="profile-newsletter"
                                            type="checkbox"
                                            onChange={(e) => updateContactData({ ...contactData, disclaimer: e.target.checked })}
                                        />
                                        <label htmlFor="profile-newsletter" className={FormStyles.checkboxLabel}>
                                            {blok.disclaimer}*
                                        </label>
                                        {showErrors && !contactData.disclaimer && (
                                            <p className={FormStyles.error}>{blok.disclaimer_error}</p>
                                        )}
                                    </div>
                                </form>

                                <div className={PromoCodeStyles.bottomWrapper}>
                                    <div>
                                        <p>{blok.mandatory_legend}</p>
                                    </div>
                                    <div>
                                        <HsButton
                                            appearance={'primary'}
                                            type={'button'}
                                            onClick={onSubmit}
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.submit_button}
                                        </HsButton>
                                    </div>
                                </div>
                            </>
                        )}

                        {pageState === 'success' && (
                            <div className={PromoCodeStyles.header}>
                                <h5 className={PromoCodeStyles.headline}>{blok.success_headline}</h5>
                                <p>{blok.success_copytext}</p>
                            </div>
                        )}

                        {pageState === 'error' && <Error storyblokCommon={storyblokCommon} />}
                    </div>
                </>
            )}
        </>
    );
};

export default AccountPromoCode;
