import React from 'react';

import * as FormStylesDr from '../../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../../_scss/modules/_forms.hs.module.scss';
import { IArticle } from '../../../_shared/interfaces/article';
import { OrderServicesArticleStoryblok, ProductStoryblok } from '../../../_shared/interfaces/storyblok';
import { getOptionByLabel } from '../../../lib/article/article';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { trackEvent } from '../../../lib/track/track';
import { IProductOptions } from '../../order/services/services';
import * as ArticleStylesDr from './article.dr.module.scss';
import * as ArticleStylesHs from './article.hs.module.scss';

const Article = ({
    blok,
    storyblokCommon,
    productOptions,
    articleIndex,
    showErrors,
    onChange,
    manufacturer,
    initialValues,
    deviceOnly,
    children,
    source,
}: {
    blok: OrderServicesArticleStoryblok;
    storyblokCommon: StoryblokCommon;
    productOptions: IProductOptions;
    articleIndex: number;
    showErrors?: boolean;
    onChange?: (article: IArticle) => any;
    manufacturer?: string;
    initialValues: IArticle;
    deviceOnly?: boolean;
    children?: any;
    source?: string;
}) => {
    const ArticleStyles = storyblokCommon.configuration.content.theme === 'dr' ? ArticleStylesDr : ArticleStylesHs;
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [currentArticle, setCurrentArticle] = React.useState<IArticle>({
        product: '',
        serialNumber: '',
        softwareVersion: '',
        serviceIntervalMonths: 0,
        service: '',
        notes: '',
    });
    const [servicesOptions, setServicesOptions] = React.useState<{ label: string; value: string }[]>([
        { label: storyblokCommon.configuration.content.select_default, value: '' },
    ]);

    const [trackSerialNumber, setTrackSerialNumber] = React.useState(true);
    const [trackSoftwareVersion, setTrackSoftwareVersion] = React.useState(true);

    const isServiceConsultantAvailable = (): boolean => {
        const { products = [] } = storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === manufacturer) ?? {};
        const originalProduct: ProductStoryblok = products.find((p) => p.name === currentArticle.product) ?? ({} as ProductStoryblok);
        return !!originalProduct.service_consultant_available;
    };

    const updateArticle = (value: string, attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion'): void => {
        let _currentArticle = { ...currentArticle, [attributeName]: value };

        if (attributeName === 'product' && manufacturer) {
            updateServicesOptions(_currentArticle.product);
            _currentArticle = getUpdatedArticle(_currentArticle, true);
        }

        setCurrentArticle(_currentArticle);

        if (onChange) {
            onChange(_currentArticle);
            trackInteraction(_currentArticle, attributeName, articleIndex);
        }
    };

    const trackInteraction = (
        article: IArticle,
        attributeName: 'product' | 'service' | 'notes' | 'serialNumber' | 'softwareVersion',
        index: number,
    ): void => {
        if (source === 'on-site-service') {
            let trackData: any;

            switch (attributeName) {
                case 'product':
                    trackData = article.product ? { name: `Product|count:${index + 1}`, value: { product: article.product } } : null;
                    break;
                case 'service':
                    trackData = article.service
                        ? {
                              name: 'Service & Accessory',
                              value: {
                                  service: servicesOptions.find((x) => x.value === article.service)?.label,
                              },
                          }
                        : null;
                    break;
                case 'serialNumber':
                    if (trackSerialNumber) {
                        setTrackSerialNumber(false);
                        trackData = { name: 'Serial Number' };
                    }
                    break;
                case 'softwareVersion':
                    if (trackSoftwareVersion) {
                        setTrackSoftwareVersion(false);
                        trackData = { name: 'Software Version' };
                    }
                    break;
            }
            if (trackData) {
                trackEvent('serviceorder.fieldChanged', {
                    serviceorder: { type: 'onsite_service', name: '1:ServiceSelection' },
                    serviceorderField: trackData,
                });
            }
        }
    };

    const getUpdatedArticle = (article: IArticle, resetService: boolean): IArticle => {
        if (article.product && manufacturer) {
            const originalData = getOptionByLabel(productOptions[manufacturer], article.product);
            article.value = article.product;
            if (resetService) {
                article.service = '';
                article.softwareVersion = '';
            }
            article.serialNumberRepresentation = originalData?.serialNumber ?? 'hidden';
            article.softwareVersionRepresentation = originalData?.softwareVersion ?? 'hidden';
            article.deviceId = originalData?.deviceId ?? undefined;
            article.product = originalData?.value ?? '';
        }
        return article;
    };

    const updateServicesOptions = (currentProduct: string): void => {
        if (manufacturer) {
            productOptions[manufacturer].forEach((productGroup) => {
                for (const product of productGroup.options) {
                    if (product.label === currentProduct && product.services.length > 0) {
                        setServicesOptions(product.services);
                        break;
                    }
                }
            });
        }
    };

    React.useEffect(() => {
        if (initialValues.product) {
            setCurrentArticle(getUpdatedArticle(initialValues, false));
            updateServicesOptions(initialValues.product);
        }
    }, []);

    return (
        <>
            {ArticleStyles && FormStyles && (
                <div className={ArticleStyles.orderServicesArticle}>
                    <form>
                        <div className={FormStyles.formField}>
                            <label htmlFor={'order-services-product-' + articleIndex}>{blok.product_label}*</label>
                            <select
                                id={'order-services-product-' + articleIndex}
                                name={'order-services-product-' + articleIndex}
                                disabled={!manufacturer}
                                onChange={(e) => updateArticle(e.target.value, 'product')}
                                value={currentArticle.value}
                                className={showErrors && manufacturer && !currentArticle.product ? FormStyles.inputValidationError : ''}
                            >
                                <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                {manufacturer &&
                                    productOptions[manufacturer].map((group: any, groupIndex: number) => {
                                        return (
                                            <optgroup key={groupIndex} label={group.label}>
                                                {group.options.map((option: { value: string; label: string }, optionIndex: number) => {
                                                    return <option key={optionIndex}>{option.label}</option>;
                                                })}
                                            </optgroup>
                                        );
                                    })}
                            </select>
                            {showErrors && manufacturer && !currentArticle.product && (
                                <p className={FormStyles.error}>{blok.product_error}</p>
                            )}
                        </div>

                        {currentArticle.product && currentArticle.serialNumberRepresentation !== 'hidden' && (
                            <div className={FormStyles.formField}>
                                <label htmlFor={'order-services-serial-number-' + articleIndex}>
                                    {blok.sn_label}
                                    {currentArticle.serialNumberRepresentation === 'mandatory' && <>*</>}
                                </label>
                                <input
                                    id={'order-services-serial-number-' + articleIndex}
                                    name={'order-services-serial-number-' + articleIndex}
                                    maxLength={40}
                                    value={currentArticle.serialNumber ?? ''}
                                    placeholder={blok.sn_placeholder}
                                    onChange={(e) => updateArticle(e.target.value, 'serialNumber')}
                                    onBlur={(e) => updateArticle(e.target.value.trim(), 'serialNumber')}
                                    className={
                                        showErrors &&
                                        manufacturer &&
                                        currentArticle.product &&
                                        currentArticle.serialNumberRepresentation === 'mandatory' &&
                                        !currentArticle.serialNumber
                                            ? FormStyles.inputValidationError
                                            : ''
                                    }
                                />
                                {showErrors &&
                                    manufacturer &&
                                    currentArticle.product &&
                                    currentArticle.serialNumberRepresentation === 'mandatory' &&
                                    !currentArticle.serialNumber && <p className={FormStyles.error}>{blok.sn_error}</p>}
                            </div>
                        )}

                        {currentArticle.product && currentArticle.softwareVersionRepresentation !== 'hidden' && (
                            <div className={FormStyles.formField}>
                                <label htmlFor={'order-services-software-version-' + articleIndex}>
                                    {blok.sv_label}
                                    {currentArticle.softwareVersionRepresentation === 'mandatory' && <>*</>}
                                </label>
                                <input
                                    id={'order-services-software-version-' + articleIndex}
                                    name={'order-services-software-version-' + articleIndex}
                                    maxLength={40}
                                    value={currentArticle.softwareVersion ?? ''}
                                    placeholder={blok.sv_placeholder}
                                    onChange={(e) => updateArticle(e.target.value, 'softwareVersion')}
                                    onBlur={(e) => updateArticle(e.target.value.trim(), 'softwareVersion')}
                                    className={
                                        showErrors &&
                                        manufacturer &&
                                        currentArticle.product &&
                                        currentArticle.softwareVersionRepresentation === 'mandatory' &&
                                        !currentArticle.softwareVersion
                                            ? FormStyles.inputValidationError
                                            : ''
                                    }
                                />
                                {showErrors &&
                                    manufacturer &&
                                    currentArticle.product &&
                                    currentArticle.softwareVersionRepresentation === 'mandatory' &&
                                    !currentArticle.softwareVersion && <p className={FormStyles.error}>{blok.sv_error}</p>}
                            </div>
                        )}

                        {!deviceOnly && (
                            <>
                                <div className={FormStyles.formField}>
                                    <label htmlFor={'order-services-service-' + articleIndex}>{blok.service_label}*</label>

                                    {blok.service_info && isServiceConsultantAvailable() && (
                                        <div
                                            style={{ paddingBottom: '3px' }}
                                            className={FormStyles.infoText}
                                            dangerouslySetInnerHTML={getRichTextMarkup(blok.service_info)}
                                        />
                                    )}

                                    <select
                                        id={'order-services-service-' + articleIndex}
                                        name={'order-services-service-' + articleIndex}
                                        disabled={!(manufacturer && currentArticle.product)}
                                        onChange={(e) => updateArticle(e.target.value, 'service')}
                                        value={currentArticle.service}
                                        className={
                                            showErrors && manufacturer && currentArticle.product && !currentArticle.service
                                                ? FormStyles.inputValidationError
                                                : ''
                                        }
                                    >
                                        <option value="">{storyblokCommon.configuration.content.select_default}</option>
                                        {servicesOptions.map((option: { label: string; value: string }, index) => {
                                            return (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    {showErrors && manufacturer && currentArticle.product && !currentArticle.service && (
                                        <p className={FormStyles.error}>{blok.service_error}</p>
                                    )}
                                </div>

                                <div className={[FormStyles.formField, FormStyles.fullWidth].join(' ')}>
                                    <label htmlFor={'order-services-notes-' + articleIndex}>{blok.notes_label}</label>
                                    <textarea
                                        id={'order-services-notes-' + articleIndex}
                                        name={'order-services-notes-' + articleIndex}
                                        value={currentArticle.notes}
                                        maxLength={600}
                                        disabled={!(manufacturer && currentArticle.product && currentArticle.service)}
                                        onChange={(e) => updateArticle(e.target.value, 'notes')}
                                        onBlur={(e) => updateArticle(e.target.value.trim(), 'notes')}
                                        placeholder={blok.notes_placeholder}
                                    />
                                </div>
                            </>
                        )}
                    </form>
                    {children}
                </div>
            )}
        </>
    );
};

export default Article;
