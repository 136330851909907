import React, { useContext } from 'react';
import { AccountContext } from '../../../../context/account/account.context';
import { IContractData } from '../../../../_shared/interfaces/contract-data';
import HsButtonSimple from '../../../_common/hs-button-simple/hs-button-simple';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import * as AccountContractDataStyles from './contract-data.module.scss';
import { AccountContractDataStoryblok } from '../../../../_shared/interfaces/storyblok';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import Error from '../../../_common/error/error';

const AccountContractData = ({ blok, storyblokCommon }: { blok: AccountContractDataStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);
    const path = require('path');

    const downloadContractDataFile = (filename: string): void => {
        accountContext.contractDataFileDownloadRequest(filename).catch((e) => {
            alert(`${storyblokCommon.configuration.content.error_headline}\n\n${storyblokCommon.configuration.content.error_copytext}`);
        });
    };

    const fileNameWithoutExtension = (filename: string): string => {
        return path.parse(filename).name;
    };

    React.useEffect(() => {
        if (!accountContext.contractDataList) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .contractDataListRequest()
                .catch(() => {
                    changeIsError(true);
                })
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    }, []);

    return (
        <div className={AccountContractDataStyles.accountContractData}>
            {isError ? (
                <Error storyblokCommon={storyblokCommon} />
            ) : (
                <>
                    <div className={AccountContractDataStyles.header}>
                        <h5 className={AccountContractDataStyles.headline}>{blok.headline}</h5>
                        <p>{blok.copytext}</p>
                    </div>

                    {!accountContext.contractDataList?.length ? (
                        <div className={`${AccountContractDataStyles.content}`}>
                            <p>{blok.no_contract_data_found}</p>
                        </div>
                    ) : (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th />
                                        <th className={`${AccountContractDataStyles.thAlignLeft}`}>
                                            <p>{blok.label_year}</p>
                                        </th>
                                        <th className={`${AccountContractDataStyles.thAlignLeft}`}>
                                            <p>{blok.label_file_name}</p>
                                        </th>
                                        <th className={`${AccountContractDataStyles.thAlignLeft}`}>
                                            <p>{blok.label_contract_number}</p>
                                        </th>
                                        <th>
                                            <p>{blok.download_button}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accountContext.contractDataList.map((contractData: IContractData, i: number) => {
                                        return (
                                            <tr className={`${AccountContractDataStyles.trItem}`} key={i}>
                                                <td className={`${AccountContractDataStyles.tdBold} ${AccountContractDataStyles.tdItem}`}>
                                                    <p>{blok.label_contract_type}</p>
                                                </td>
                                                <td className={`${AccountContractDataStyles.tdItem}`}>
                                                    <p>{contractData.year}</p>
                                                </td>
                                                <td className={`${AccountContractDataStyles.tdItem}`}>
                                                    <p>{fileNameWithoutExtension(contractData.fileName)}</p>
                                                </td>
                                                <td className={`${AccountContractDataStyles.tdItem}`}>
                                                    <p>{contractData.contractNumber}</p>
                                                </td>
                                                <td
                                                    className={`${AccountContractDataStyles.tdItem} ${AccountContractDataStyles.tdAlignCenter}`}
                                                >
                                                    <HsButtonSimple
                                                        icon="download"
                                                        size="large"
                                                        onClick={() => downloadContractDataFile(contractData.fileName)}
                                                        storyblokCommon={storyblokCommon}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {accountContext.contractDataList.map((contractData: IContractData, i: number) => {
                                return (
                                    <div
                                        className={`${AccountContractDataStyles.content} ${AccountContractDataStyles.gridContainer}`}
                                        key={i}
                                    >
                                        <div className={`${AccountContractDataStyles.gridItemText}`}>
                                            <p className={`${AccountContractDataStyles.gridHeadline}`}>{blok.label_contract_type}</p>
                                            <p>
                                                {blok.label_file_name}: {fileNameWithoutExtension(contractData.fileName)}
                                            </p>
                                            <p>
                                                {blok.label_contract_number}: {contractData.contractNumber}
                                            </p>
                                            <p>
                                                {blok.label_year}: {contractData.year}
                                            </p>
                                        </div>

                                        <div className={`${AccountContractDataStyles.button} ${AccountContractDataStyles.gridItem}`}>
                                            <HsButtonSimple
                                                icon="download"
                                                size="large"
                                                onClick={() => downloadContractDataFile(contractData.fileName)}
                                                storyblokCommon={storyblokCommon}
                                            >
                                                {blok.download_button}
                                            </HsButtonSimple>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AccountContractData;
