import React, { useContext } from 'react';
import { AccountContext } from '../../../../context/account/account.context';
import HsButton from '../../../_common/hs-button/hs-button';
import AccountDevicesDevice from '../device/device';
import LoadingAnimationContext from '../../../../context/loading-animation/loading-animation.context';
import Error from '../../../_common/error/error';
import { IDevice } from '../../../../_shared/interfaces/device';
import { AccountDevicesDeviceStoryblok, AccountDevicesOverviewStoryblok } from '../../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';

import * as AccountDevicesOverviewStyles from './overview.module.scss';
import { navigate } from 'gatsby';
import { getProduct } from '../../../../lib/article/article';
import SbEditable from 'storyblok-react';
import { useFormStyles } from '../../../../context/storyblok/storyblok.context';

const AccountDevicesOverview = ({
    blok,
    blokDevice,
    storyblokCommon,
}: {
    blok: AccountDevicesOverviewStoryblok;
    blokDevice: AccountDevicesDeviceStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);

    const addDevice = (): void => {
        navigate(self.location.pathname, { state: { pageId: 'DevicesAdd' } });
    };

    const toogleNewsletter = (e: React.ChangeEvent<HTMLInputElement>): void => {
        loadingAnimationContext.showLoadingAnimation();
        accountContext
            .updateAccountData({ newsletter: e.target.checked })
            .catch(() => {
                changeIsError(true);
            })
            .finally(() => {
                loadingAnimationContext.hideLoadingAnimation();
            });
    };
    const formStyles = useFormStyles();
    React.useEffect(() => {
        if (!accountContext.devices) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .devicesRequest()
                .catch(() => {
                    changeIsError(true);
                })
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    }, []);

    return (
        <div className={AccountDevicesOverviewStyles.accountDevicesOverview}>
            <h5 className={AccountDevicesOverviewStyles.headline}>{blok.headline}</h5>
            {blok.copytext && <p>{blok.copytext}</p>}
            {!isError && accountContext.devices && (
                <>
                    {blok.field_newsletter_legend && (
                        <fieldset className={AccountDevicesOverviewStyles.newsletter}>
                            <legend>{blok.field_newsletter_legend}</legend>
                            <div>
                                <form>
                                    <div>
                                        <input
                                            id="profile-newsletter"
                                            type="checkbox"
                                            onChange={toogleNewsletter}
                                            checked={Boolean(accountContext.accountData?.newsletter)}
                                        />
                                        <label htmlFor="profile-newsletter" className={formStyles.checkboxLabel}>
                                            {blok.field_newsletter_label}
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </fieldset>
                    )}
                    {!accountContext.devices.length ? (
                        <p>{blok.no_devices}</p>
                    ) : (
                        <>
                            {accountContext.devices.length > 1 && (
                                <div style={{ textAlign: 'right' }}>
                                    <HsButton appearance="secondary" onClick={addDevice} storyblokCommon={storyblokCommon}>
                                        {blok.add}
                                    </HsButton>
                                </div>
                            )}

                            <ul className={AccountDevicesOverviewStyles.list}>
                                {accountContext.devices.map((device: IDevice, index) => {
                                    return (
                                        <li key={index}>
                                            <SbEditable content={blokDevice}>
                                                <AccountDevicesDevice
                                                    blok={blokDevice}
                                                    device={device}
                                                    isInvalid={
                                                        typeof getProduct(
                                                            device.manufacturer,
                                                            device.productName,
                                                            storyblokCommon.workshopServices.content,
                                                        ) === 'undefined'
                                                    }
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </SbEditable>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}

                    <div style={{ textAlign: 'right' }}>
                        <HsButton appearance="secondary" onClick={addDevice} storyblokCommon={storyblokCommon}>
                            {blok.add}
                        </HsButton>
                    </div>
                </>
            )}
            {isError && <Error storyblokCommon={storyblokCommon} />}
        </div>
    );
};

export default AccountDevicesOverview;
