import React, { useContext, useState } from 'react';
import { AccountContext } from '../../../../context/account/account.context';
import { IDevice } from '../../../../_shared/interfaces/device';
import HsButtonSimple from '../../../_common/hs-button-simple/hs-button-simple';
import { StoryblokCommon } from '../../../../lib/storyblok/storyblok';
import * as AccountDevicesDeviceStyles from './device.module.scss';
import { AccountDevicesDeviceStoryblok } from '../../../../_shared/interfaces/storyblok';
import { FormattedDate } from 'react-intl';
import { navigate } from 'gatsby';
import { getProduct } from '../../../../lib/article/article';
import { trackEvent } from '../../../../lib/track/track';

const AccountDevicesDevice = ({
    device,
    isInvalid = false,
    blok,
    storyblokCommon,
}: {
    device: IDevice;
    isInvalid?: boolean;
    blok: AccountDevicesDeviceStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const accountContext = useContext(AccountContext);
    const [isMarked, setIsMarked] = useState(false);
    const [recommendedServiceDate, setRecommendedServiceDate] = useState<Date>();
    const [recommendedServiceDateExpired, setRecommendedServiceDateExpired] = useState(false);

    const deleteDevice = (e: any) => {
        e.preventDefault();
        setIsMarked(true);
        setTimeout(() => {
            if (window.confirm(blok.delete_confirmation)) {
                trackEvent('device.delete', {});
                accountContext.deleteDevice(device.id ? device.id : '');
            } else {
                setIsMarked(false);
            }
        });
    };

    const calculateRecommendedServiceDate = (intervalMonths: number) => {
        if (device.lastServiceDate) {
            const lastService = new Date(device.lastServiceDate);
            const nextService = new Date(lastService.setMonth(lastService.getMonth() + intervalMonths));
            setRecommendedServiceDate(nextService);
            setRecommendedServiceDateExpired(isDateExpired(nextService));
        }
    };

    const isDateExpired = (d: Date): boolean => {
        return d.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
    };

    React.useEffect(() => {
        const productDetails = getProduct(device.manufacturer, device.productName, storyblokCommon.workshopServices.content);
        const interval = productDetails?.service_interval ? parseFloat(productDetails.service_interval.toString()) : 0;
        if (interval) {
            calculateRecommendedServiceDate(interval);
        }
    }, [device]);

    return (
        <div
            className={[AccountDevicesDeviceStyles.accountDevice, isMarked ? AccountDevicesDeviceStyles.marked : ''].join(' ')}
            id={device.id}
        >
            <div className={AccountDevicesDeviceStyles.content}>
                <p>
                    <strong>{device.productName}</strong>
                </p>

                <ul>
                    <li>
                        <p>
                            <small>{blok.label_serial_number}</small>
                        </p>
                        <p>{device.serialNumber}</p>
                    </li>

                    <li>
                        <p>
                            <small>{blok.label_manufacturer}</small>
                        </p>
                        <p>{device.manufacturer}</p>
                    </li>

                    <li>
                        <p>
                            <small>{blok.last_service_date}</small>
                        </p>

                        {device.lastServiceDate ? (
                            <p>
                                <FormattedDate value={device.lastServiceDate} year="numeric" month="long" day="numeric" />
                            </p>
                        ) : (
                            <p>-</p>
                        )}
                    </li>

                    <li>
                        <p>
                            <small>{blok.recommended_service_date}</small>
                        </p>
                        <p>
                            {recommendedServiceDate ? (
                                <span className={recommendedServiceDateExpired ? AccountDevicesDeviceStyles.expired : ''}>
                                    <FormattedDate value={recommendedServiceDate} year="numeric" month="long" day="numeric" />
                                </span>
                            ) : (
                                <>-</>
                            )}
                        </p>
                    </li>

                    <li>
                        <p>
                            <small>{blok.inventory_number}</small>
                        </p>
                        <p>{device.inventoryNumber ?? '-'}</p>
                    </li>
                </ul>
            </div>

            <div className={AccountDevicesDeviceStyles.bottom}>
                <div>
                    {isInvalid && (
                        <p className={AccountDevicesDeviceStyles.invalid}>
                            <small>{blok.invalid_device}</small>
                        </p>
                    )}
                </div>
                <div className={AccountDevicesDeviceStyles.buttons}>
                    <HsButtonSimple onClick={deleteDevice} icon="delete" storyblokCommon={storyblokCommon}>
                        {blok.delete_button}
                    </HsButtonSimple>
                    <HsButtonSimple
                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'DevicesEdit', deviceId: device.id } })}
                        icon="edit"
                        disabled={isInvalid}
                        storyblokCommon={storyblokCommon}
                    >
                        {blok.edit_button}
                    </HsButtonSimple>
                </div>
            </div>
        </div>
    );
};

export default AccountDevicesDevice;
