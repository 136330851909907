import React, { ChangeEventHandler, FC } from 'react';
import { useFormStyles, useStoryblokConfiguration } from '../../../context/storyblok/storyblok.context';

type Props = {
    value: string | undefined | null;
    onChange: (v: string) => void;
    showErrors?: boolean;
    errorMessage?: string;
    label?: string;
    id?: string;
};

export const SectorSelector: FC<Props> = ({ value, onChange, showErrors, errorMessage, label, id = 'selector' }) => {
    const styles = useFormStyles();
    const { professions_sectors: [{ values = [] }] = [{}], default_value } = useStoryblokConfiguration();
    const handleSelectSector: ChangeEventHandler<HTMLSelectElement> = ({ target }) => onChange(target.value);
    const currentValue = value ?? '';

    if (values.length <= 0) {
        return <></>;
    }

    return (
        <div className={styles.formField}>
            {label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                name="sector"
                onChange={handleSelectSector}
                value={currentValue}
                className={showErrors && !value ? styles.inputValidationError : ''}
            >
                <option selected={!Boolean(value)} value="">
                    {default_value}
                </option>
                {values.map((option, index) => {
                    return (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    );
                })}
            </select>
            {showErrors && !value && <p className={styles.error}>{errorMessage}</p>}
        </div>
    );
};

export default SectorSelector;
