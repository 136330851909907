import React from 'react';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { AccountProfileEditPhoneStoryblok } from '../../../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';
import { navigate } from 'gatsby';
import { isPhoneNumberValid } from '../../../../../lib/helpers/helpers';

import * as AccountProfileEditPhoneNumberStyles from './phone-number.module.scss';

import * as FormStylesHs from '../../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../../_scss/modules/_forms.dr.module.scss';

const AccountProfileEditPhoneNumber = ({
    blok,
    storyblokCommon,
}: {
    blok: AccountProfileEditPhoneStoryblok;
    storyblokCommon: StoryblokCommon;
}) => {
    const accountContext = React.useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = React.useContext(LoadingAnimationContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const [isError, changeIsError] = React.useState(false);
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const [phoneNumber, changePhoneNumber] = React.useState(accountContext.accountData?.phoneNumber ?? '');
    const [isValidationError, changeIsValidationError] = React.useState(false);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (phoneNumber === accountContext.accountData?.phoneNumber) {
            navigate(self.location.pathname, { state: { pageId: 'Profile' } });
        } else {
            let isValid = true;
            if (phoneNumber) {
                isValid = isPhoneNumberValid(phoneNumber);
                changeIsValidationError(!isValid);
            }
            if (isValid) {
                showLoadingAnimation();
                accountContext
                    .updateAccountData({ phoneNumber })
                    .then(() => {
                        hideLoadingAnimation();
                        navigate(self.location.pathname, { state: { pageId: 'Profile' } });
                    })
                    .catch(() => {
                        hideLoadingAnimation();
                        changeIsError(true);
                    });
            }
        }
    };

    const getProfileData = () => {
        if (!accountContext.accountData) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .accountDataRequest()
                .catch(() => {
                    changeIsError(true);
                })
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    };

    React.useEffect(() => {
        getProfileData();
    }, []);

    React.useEffect(() => {
        changePhoneNumber(accountContext.accountData?.phoneNumber ?? '');
    }, [accountContext.accountData?.phoneNumber]);

    return (
        <>
            {FormStyles && (
                <div className={AccountProfileEditPhoneNumberStyles.accountProfileEditPhoneNumber}>
                    {!isError && accountContext.accountData && (
                        <>
                            <div className={AccountProfileEditPhoneNumberStyles.header}>
                                <h5>{blok.headline}</h5>
                                <p>{blok.copytext}</p>
                            </div>

                            <form noValidate={true} onSubmit={onSubmit}>
                                <div className={FormStyles.formField}>
                                    <label htmlFor="edit-phone-number">{blok.label}</label>
                                    <input
                                        id="edit-phone-number"
                                        type="tel"
                                        autoComplete="tel"
                                        maxLength={100}
                                        onChange={(e) => changePhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                    />
                                    {isValidationError && phoneNumber && !isPhoneNumberValid(phoneNumber) && (
                                        <p className={FormStyles.error}>
                                            {storyblokCommon.configuration.content.contact_phone_error_format}
                                        </p>
                                    )}
                                </div>

                                <div className={AccountProfileEditPhoneNumberStyles.buttonWrapper}>
                                    <HsButton
                                        appearance="secondary"
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.button_cancel}
                                    </HsButton>
                                    <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                        {blok.button_save}
                                    </HsButton>
                                </div>
                            </form>
                        </>
                    )}
                    {isError && <Error storyblokCommon={storyblokCommon} />}
                </div>
            )}
        </>
    );
};

export default AccountProfileEditPhoneNumber;
