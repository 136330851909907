import React from 'react';
import { getRichTextMarkup, StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AccountCostEstimatesStoryblok } from '../../../_shared/interfaces/storyblok';
import * as CostEstimatesStyles from './cost-estimates.module.scss';
import HsButton from '../../_common/hs-button/hs-button';
import { ICostEstimate, ICostEstimateData } from '../../../_shared/interfaces/cost-estimate';
import { navigate } from 'gatsby';
import { OrderContext } from '../../../context/order/order.context';
import { IAddress } from '../../../_shared/interfaces/address';
import AccountContext from '../../../context/account/account.context';

const AccountCostEstimates = ({ blok, storyblokCommon }: { blok: AccountCostEstimatesStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext(AccountContext);
    const { resetOrder, updateOrder } = React.useContext(OrderContext);
    const [costEstimates, setCostEstimates] = React.useState<ICostEstimate[] | undefined>(undefined);

    const openOrder = (costEstimate: ICostEstimate) => {
        resetOrder().then(() => {
            updateOrder(costEstimate.order);
            navigate('/' + blok.order_page.cached_url, { state: { step: 'cost-estimate', costEstimate } });
        });
    };

    const getProofedCostEstimates = (data: ICostEstimateData[]): ICostEstimate[] => {
        const results: ICostEstimate[] = [];

        data.forEach((c) => {
            const result: ICostEstimate = {
                id: c.customerRequestNumber,
                date: c.dateTime,
                order: {
                    costEstimationParentSysId: c.customerRequestSysId,
                    invoiceNote: c.serviceOrder.invoiceNote,
                    additionalCosts: c.serviceOrder.service.additionalCosts.toString(),
                    articles: [],
                    customer: { ...c.serviceOrder.customer, eMail: c.serviceOrder.customer.email },
                    deliveryFromCustomer: {
                        type: c.serviceOrder.shippingToWorkshop.isPickup ? 'pickup' : 'self-organized',
                        price: c.serviceOrder.shippingToWorkshop.price,
                        pickupDetails: {
                            date: c.serviceOrder.shippingToWorkshop.pickupDate,
                            address: {
                                ...c.serviceOrder.shippingToWorkshop.address,
                                postalCode: c.serviceOrder.shippingToWorkshop.address?.zipCode ?? '',
                            } as IAddress,
                            phone: c.serviceOrder.shippingToWorkshop.phoneNumber,
                            notes: c.serviceOrder.shippingToWorkshop.message,
                        },
                    },

                    deliveryToCustomer: {
                        address: {
                            ...c.serviceOrder.returnFromWorkshop.address,
                            postalCode: c.serviceOrder.returnFromWorkshop.address.zipCode,
                        },
                        type: c.serviceOrder.returnFromWorkshop.isSelfPickup ? 'self_pickup' : 'service_provider',
                        notes: c.serviceOrder.returnFromWorkshop.message,
                        price: c.serviceOrder.returnFromWorkshop.price,
                    },
                    invoice: {
                        address: { ...c.serviceOrder.payment.address, postalCode: c.serviceOrder.payment.address.zipCode },
                        customerOrderNumber: c.serviceOrder.payment.customerOrderNumber,
                    },
                    manufacturer: c.serviceOrder.service.manufacturer.name,
                    payment: { type: 1 },
                },
                pricesChanges: false,
                missingProducts: [],
            };

            const { products = [] } =
                storyblokCommon.workshopServices.content.manufacturer.find((m) => m.name === c.serviceOrder.service.manufacturer.name) ??
                {};

            // if manufacturer exists
            if (products) {
                c.serviceOrder.service.productServices.forEach((productService) => {
                    // find the product
                    const product = products.find((p) => p.name === productService.productName);
                    // find the service
                    if (product) {
                        const service = product.services.find((s) => s.name === productService.serviceName);

                        if (service) {
                            if (Number(service.price) !== Number(productService.priceForService)) {
                                result.pricesChanges = true;
                            }

                            if (productService.additionalServices) {
                                productService.additionalServices.forEach((additionalService) => {
                                    if (!service.additional_services) {
                                        result.missingProducts?.push({
                                            product: productService.productName,
                                            service: productService.serviceName,
                                            additionalService: additionalService.name,
                                            price: additionalService.price,
                                        });
                                    } else {
                                        const as = service.additional_services.find((a) => a.name === additionalService.name);
                                        if (!as) {
                                            result.missingProducts?.push({
                                                product: productService.productName,
                                                service: productService.serviceName,
                                                additionalService: additionalService.name,
                                                price: additionalService.price,
                                            });
                                        } else {
                                            if (Number(as.price ?? 0) !== Number(additionalService.price)) {
                                                result.pricesChanges = true;
                                            }
                                        }
                                    }
                                });
                            }

                            result.order.articles?.push({
                                product: productService.productName,
                                serialNumber: productService.serialNumber,
                                service: service._uid,
                                serviceName: productService.serviceName,
                                serviceIntervalMonths: productService.serviceIntervalMonths,
                                notes: productService.messageForWorkshop,
                                additionalServices: productService.additionalServices?.map((as) => {
                                    return { ...as, copytext: '' };
                                }),
                            });
                        } else {
                            result.missingProducts?.push({
                                product: productService.productName,
                                service: productService.serviceName,
                                price: productService.priceForService,
                            });
                        }
                    } else {
                        result.missingProducts?.push({
                            product: productService.productName,
                            service: productService.serviceName,
                            price: productService.priceForService,
                        });
                    }
                });

                if (result.order.articles?.length) {
                    results.push(result);
                }
            }
        });

        return results;
    };

    React.useEffect(() => {
        if (accountContext.costEstimates) {
            setCostEstimates(getProofedCostEstimates(accountContext.costEstimates));
        }
    }, [accountContext.costEstimates]);

    React.useEffect(() => {
        if (!accountContext.costEstimates) {
            accountContext.costEstimatesRequest();
        }
    }, []);

    return (
        <div className={CostEstimatesStyles.costEstimates}>
            <div className={CostEstimatesStyles.header}>
                <h5 className={CostEstimatesStyles.headline}>{blok.headline}</h5>

                {!costEstimates && <p>{blok.loading}</p>}

                {costEstimates && (
                    <>
                        {costEstimates.length ? (
                            <div dangerouslySetInnerHTML={getRichTextMarkup(blok.copytext)} />
                        ) : (
                            <p>{blok.copytext_no_costs_estimates}</p>
                        )}
                    </>
                )}
            </div>

            {costEstimates && costEstimates.length > 0 && (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <p>{blok.column_headline_date}</p>
                                </th>
                                <th>
                                    <p>{blok.column_headline_reference_number}</p>
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {costEstimates.map((costEstimate) => {
                                return (
                                    <tr key={costEstimate.id}>
                                        <td>
                                            <p>
                                                {new Date(costEstimate.date).toLocaleDateString(
                                                    `${storyblokCommon.configuration.content.language}-${process.env.GATSBY_COUNTRY}`,
                                                )}
                                            </p>
                                        </td>
                                        <td>
                                            <p>{costEstimate.id}</p>
                                        </td>
                                        <td>
                                            <HsButton
                                                size="small"
                                                storyblokCommon={storyblokCommon}
                                                onClick={() => openOrder(costEstimate)}
                                            >
                                                {blok.order_button_text}
                                            </HsButton>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default AccountCostEstimates;
