import React from 'react';
import AccountContext, { IAccountContext } from '../../../context/account/account.context';
import HsButton from '../../_common/hs-button/hs-button';
import { StoryblokCommon } from '../../../lib/storyblok/storyblok';
import { AccountUnauthorizedStoryblok } from '../../../_shared/interfaces/storyblok';

const AccountUnauthorized = ({ blok, storyblokCommon }: { blok: AccountUnauthorizedStoryblok; storyblokCommon: StoryblokCommon }) => {
    const accountContext = React.useContext<IAccountContext>(AccountContext);

    return (
        <div>
            <h4>{blok.headline}</h4>
            <p>
                {blok.copytext}
                <span style={{ display: 'block', marginTop: '16px' }}>
                    <HsButton
                        onClick={() =>
                            accountContext.signIn(
                                storyblokCommon.configuration.content.language,
                                storyblokCommon.configuration.content.theme,
                            )
                        }
                        storyblokCommon={storyblokCommon}
                    >
                        {blok.button_text}
                    </HsButton>
                </span>
            </p>
        </div>
    );
};

export default AccountUnauthorized;
