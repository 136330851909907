// extracted by mini-css-extract-plugin
export var accountContractData = "contract-data-module--account-contract-data--8b618";
export var button = "contract-data-module--button--0b307";
export var checkbox = "contract-data-module--checkbox--16dc2";
export var content = "contract-data-module--content--7f655";
export var gridContainer = "contract-data-module--grid-container--883d2";
export var gridHeadline = "contract-data-module--grid-headline--6a4a5";
export var gridItem = "contract-data-module--grid-item--5eea7";
export var gridItemText = "contract-data-module--grid-item-text--6d9c8";
export var header = "contract-data-module--header--b41e8";
export var headline = "contract-data-module--headline--1dfa4";
export var tdAlignCenter = "contract-data-module--td-align-center--70b1c";
export var tdBold = "contract-data-module--td-bold--2f510";
export var tdItem = "contract-data-module--td-item--39999";
export var thAlignLeft = "contract-data-module--th-align-left--ad45a";
export var trItem = "contract-data-module--tr-item--3128d";