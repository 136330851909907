import React, { FC, FormEventHandler, useContext, useState } from 'react';
import { AccountContext } from '../../../../../context/account/account.context';
import LoadingAnimationContext from '../../../../../context/loading-animation/loading-animation.context';
import HsButton from '../../../../_common/hs-button/hs-button';
import Error from '../../../../_common/error/error';
import { AccountProfileEditContractNumberStoryblok } from '../../../../../_shared/interfaces/storyblok';
import { StoryblokCommon } from '../../../../../lib/storyblok/storyblok';
import { navigate } from 'gatsby';
import { scrollToFirstError, replaceText } from '../../../../../lib/helpers/helpers';
import * as contractNumberStyles from './contract-number.module.scss';
import { useFormStyles, useStoryblokConfiguration } from '../../../../../context/storyblok/storyblok.context';
import * as FormStylesHs from '../../../../../_scss/modules/_forms.hs.module.scss';
import * as FormStylesDr from '../../../../../_scss/modules/_forms.dr.module.scss';
import { IAddress } from '../../../../../_shared/interfaces/address';
import { IAccountData } from '../../../../../_shared/interfaces/account-data';

type Props = {
    blok: AccountProfileEditContractNumberStoryblok;
};

const AccountProfileEditContractNumber: FC<Props> = ({ blok }) => {
    const { accountData, updateAccountData, accountDataRequest, resetContractDataList, addresses } = useContext(AccountContext);
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);
    const [isError, setIsError] = useState(false);
    const formStyles = useFormStyles();
    const [contractNumber, setContractNumber] = useState(accountData?.contractNumber);
    const [disclaimerContractData, setDisclaimerContractData] = useState(accountData?.disclaimerContractData);
    const [showErrors, setShowErrors] = React.useState(false);
    const configuration = useStoryblokConfiguration();
    const storyblokCommon = { configuration: { content: configuration ?? {} } } as StoryblokCommon;
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;

    const validateContractNumber = (): boolean => {
        return contractNumber ? contractNumber?.length === 10 : true;
    };

    const validateDisclaimerContractData = (): boolean => {
        return contractNumber ? disclaimerContractData !== undefined : true;
    };

    const replaceDisclaimerText = (text: string | undefined): string | undefined => {
        if (!text) {
            return text;
        }
        let results: string = replaceText(text, '%NAME%', (accountData?.firstName ?? '') + ' ' + (accountData?.lastName ?? ''));
        results = replaceText(results, '%FIRMA%', (addresses && addresses[0]?.company) ?? '');
        return results;
    };

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!validateContractNumber() || !validateDisclaimerContractData()) {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        } else {
            if (contractNumber === accountData?.profession) {
                return navigate(self.location.pathname, { state: { pageId: 'Profile' } });
            }
            showLoadingAnimation();
            try {
                await updateAccountData({ contractNumber, disclaimerContractData });
                await resetContractDataList();
                hideLoadingAnimation();
                navigate(self.location.pathname, { state: { pageId: 'Profile' } });
            } catch {
                hideLoadingAnimation();
                setIsError(true);
            }
        }
    };

    const getProfileData = async () => {
        showLoadingAnimation();
        try {
            await accountDataRequest();
        } catch {
            setIsError(true);
        } finally {
            hideLoadingAnimation();
        }
    };

    React.useEffect(() => {
        if (accountData) return;
        getProfileData();
    }, []);

    return (
        <>
            {formStyles && (
                <div>
                    {!isError && accountData && (
                        <>
                            <div className={contractNumberStyles.header}>
                                <h5>{blok.headline}</h5>
                                <p>{blok.copytext}</p>
                            </div>

                            <form noValidate={true} onSubmit={onSubmit}>
                                <div className={formStyles.formField}>
                                    <label htmlFor="edit-contract-number">{blok.label}</label>
                                    <input
                                        id="edit-contract-number"
                                        type="text"
                                        minLength={10}
                                        maxLength={10}
                                        onChange={(e) => {
                                            setDisclaimerContractData(undefined);
                                            setContractNumber(e.target.value === '' ? undefined : e.target.value);
                                        }}
                                        value={contractNumber}
                                        className={showErrors && !validateContractNumber() ? formStyles.inputValidationError : ''}
                                    />
                                    {showErrors && !validateContractNumber() && <p className={formStyles.error}>{blok.label_error}</p>}
                                </div>

                                <div>
                                    <input
                                        disabled={!contractNumber}
                                        id="contract-disclaimer"
                                        type="checkbox"
                                        checked={!!disclaimerContractData}
                                        onChange={(e) =>
                                            setDisclaimerContractData(
                                                e.target.checked
                                                    ? {
                                                          date: new Date(),
                                                          disclaimerText: replaceDisclaimerText(blok.checkbox_label),
                                                      }
                                                    : undefined,
                                            )
                                        }
                                    />
                                    <label htmlFor="contract-disclaimer" className={formStyles.checkboxLabel}>
                                        {replaceDisclaimerText(blok.checkbox_label)}
                                    </label>
                                    {showErrors && !validateDisclaimerContractData() && (
                                        <p className={formStyles.error}>{blok.disclaimer_error}</p>
                                    )}
                                </div>

                                <div className={contractNumberStyles.buttonWrapper}>
                                    <HsButton
                                        appearance="secondary"
                                        onClick={() => navigate(self.location.pathname, { state: { pageId: 'Profile' } })}
                                        storyblokCommon={storyblokCommon}
                                    >
                                        {blok.button_cancel}
                                    </HsButton>
                                    <HsButton appearance="primary" type={'submit'} storyblokCommon={storyblokCommon}>
                                        {blok.button_save}
                                    </HsButton>
                                </div>
                            </form>
                        </>
                    )}
                    {isError && <Error storyblokCommon={storyblokCommon} />}
                </div>
            )}
        </>
    );
};

export default AccountProfileEditContractNumber;
